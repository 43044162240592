import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import rootReducer from "../Reducers";
import { createTransform } from "redux-persist";

// Transform for `authentication` reducer to only persist `isAuthenticated`
const authenticationTransform = createTransform(
  (inboundState) => ({ isAuthenticated: inboundState.isAuthenticated }),
  (outboundState) => outboundState,
  { whitelist: ["authentication"] }
);

// Transform for `analysis` reducer to only persist `AddedFileID`
const analysisTransform = createTransform(
  (inboundState) => ({ AddedFileID: inboundState.AddedFileID }),
  (outboundState) => outboundState,
  { whitelist: ["analysis"] }
);

const isCollapsedTransform = createTransform(
  (inboundState) => ({ isCollapsed: inboundState.isCollapsed }),
  (outboundState) => outboundState,
  { whitelist: ["interactions"] }
);

const modelTransform = createTransform(
  (inboundState) => ({ model: inboundState.model }),
  (outboundState) => outboundState,
  { whitelist: ["AI"] }
);

const modelNameTransform = createTransform(
  (inboundState) => ({ modelName: inboundState.modelName }),
  (outboundState) => outboundState,
  { whitelist: ["AI"] }
);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authentication", "analysis", "ai"], // Make sure this matches reducer names in rootReducer
  transforms: [
    authenticationTransform,
    analysisTransform,
    isCollapsedTransform,
    modelTransform,
    modelNameTransform,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;
