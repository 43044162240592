import React, { Component } from "react";
import CSVProcessingOnSiteSVG from "../../../Assets/svg/CSVProcessingOnSiteSVG.svg";

class CSVProcessingOnSite extends Component {
  render() {
    return (
      <div className="flex flex-col gap-[2rem] w-[44.938rem] h-[29.375rem] bg-white justify-center rounded-lg items-center z-100">
        <img
          src={CSVProcessingOnSiteSVG}
          alt="AccountCreatedSuccessSVG"
          className="w-[9.688rem] h-[9.688rem]"
        />
        <div className="font-[600] font-inter text-[1.5rem] w-[23.569rem] text-center leading-[1.828rem]">
          Please Wait while file is being processed
        </div>
      </div>
    );
  }
}

export default CSVProcessingOnSite;
