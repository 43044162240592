import React, { Component } from "react";
import { Link } from "react-router-dom";
import CESSVG from "../../Assets/svg/CESSVG.svg";
import SignUpPageImage from "../../Assets/jpeg/LoginOverLay.jpg";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import { SignupAction } from "../../Actions/Authentication";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../Animations/Spinner/Spinner";

class SignUp extends Component {
  state = {
    first_name: "",
    last_name: "",
    middle_name: "",
    company_name: "",
    email: "",
    password: "",
    confirm_password: "",
    acceptTerms: false,
    signingup: false,
  };

  static propTypes = {
    SignupAction: PropTypes.func.isRequired,
    isSignupSuccess: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      middle_name,
      company_name,
      email,
      password,
      confirm_password,
      acceptTerms,
    } = this.state;

    if (!acceptTerms) {
      alert("Please accept the terms and conditions.");
      return;
    }

    if (!email || !password || !confirm_password) {
      alert("Please fill in all required fields.");
      return;
    }

    this.setState({ signingup: true });

    setTimeout(() => {
      this.setState({ signingup: false });
    }, 3000);

    // Call the Signup action with the correctly extracted parameters
    this.props.SignupAction(
      first_name,
      last_name,
      middle_name,
      company_name,
      email,
      password,
      confirm_password
    );
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  splitFullName = (fullName) => {
    const nameParts = fullName.trim().split(" ");
    let firstName = "";
    let middleName = "";
    let lastName = "";

    if (nameParts.length === 1) {
      firstName = nameParts[0];
    } else if (nameParts.length === 2) {
      firstName = nameParts[0];
      lastName = nameParts[1];
    } else {
      firstName = nameParts[0];
      middleName = nameParts.slice(1, -1).join(" ");
      lastName = nameParts[nameParts.length - 1];
    }

    // Set individual fields instead of nesting them
    this.setState({
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
    });
  };

  onCheckboxChange = (e) => {
    this.setState({ acceptTerms: e.target.checked });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.isSignupSuccess &&
      this.props.isSignupSuccess !== prevProps.isSignupSuccess
    ) {
      this.props.navigate("/verify-email");
    }
  }

  render() {
    const { full_name, company_name, email, password, confirm_password } =
      this.state;

    return (
      <div className="flex w-full h-screen justify-center items-center">
        <div className="flex flex-row">
          <div className="flex items-center justify-center w-[32.063rem] h-[55.188rem]">
            <div className="flex flex-col justify-center items-center w-[27.313rem] gap-[0.5rem]">
              <div className="w-[4.438rem] h-[1.925rem]">
                <img src={CESSVG} alt="CESSVG" className="w-full h-full" />
              </div>
              <div className="font-SFPro font-[700] text-[2.5rem]">
                Create an Account
              </div>
              <div className="text-center font-SFPro font-[400] text-[1.25rem] text-[#443F3F]">
                Please input your details to create an account and enjoy our
                services
              </div>
              <form
                onSubmit={this.onSubmit}
                className="flex flex-col gap-[0.5rem]"
              >
                <div>
                  <div className="font-inter font-[600]">Full Name</div>
                  <input
                    type="text"
                    name="full_name"
                    value={full_name}
                    onChange={(e) => this.splitFullName(e.target.value)}
                    placeholder="Enter your first, middle, and last name"
                    className="w-[27.313rem] h-[3.75rem] border-[1px] border-[#8E3A90] rounded-[0.625rem] mt-[0.4rem] pl-[1.25rem] focus:outline-[#8E3A90] bg-white"
                  />
                </div>
                <div>
                  <div className="font-inter font-[600]">Company Name</div>
                  <input
                    type="text"
                    name="company_name"
                    value={company_name}
                    onChange={this.onChange}
                    placeholder="Enter your company's name"
                    className="w-[27.313rem] h-[3.75rem] border-[1px] border-[#8E3A90] rounded-[0.625rem] mt-[0.4rem] pl-[1.25rem] focus:outline-[#8E3A90] bg-white"
                  />
                </div>
                <div>
                  <div className="font-inter font-[600]">Email</div>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    placeholder="Enter your email"
                    className="w-[27.313rem] h-[3.75rem] border-[1px] border-[#8E3A90] rounded-[0.625rem] mt-[0.4rem] pl-[1.25rem] focus:outline-[#8E3A90] bg-white"
                  />
                </div>
                <div>
                  <div className="font-inter font-[600]">Password</div>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    placeholder="Enter password"
                    autocomplete="new-password"
                    className="w-[27.313rem] h-[3.75rem] border-[1px] border-[#8E3A90] rounded-[0.625rem] mt-[0.4rem] pl-[1.25rem] focus:outline-[#8E3A90] bg-white"
                  />
                </div>
                <div>
                  <div className="font-inter font-[600]">Confirm Password</div>
                  <input
                    type="password"
                    name="confirm_password"
                    value={confirm_password}
                    onChange={this.onChange}
                    placeholder="Confirm password"
                    className="w-[27.313rem] h-[3.75rem] border-[1px] border-[#8E3A90] rounded-[0.625rem] mt-[0.4rem] pl-[1.25rem] focus:outline-[#8E3A90] bg-white"
                  />
                </div>
                <div className="flex w-full h-[2.75rem] justify-center items-center">
                  <div className="flex justify-center items-center gap-[1rem]">
                    <input
                      className="w-6 h-6"
                      type="checkbox"
                      checked={this.state.acceptTerms}
                      onChange={this.onCheckboxChange}
                    />
                    <div className="font-inter font-[400] text-[1rem]">
                      Accept all <Link className="text-[#8E3A90]">terms</Link>{" "}
                      and <Link className="text-[#8E3A90]">conditions</Link> of
                      this website
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <button
                    type="submit"
                    className="flex justify-center items-center gap-[1rem] w-full h-[3.75rem] rounded-[2.438rem] bg-[#8E3B8D] text-white text-[600] font-inter text-[1.5rem]"
                  >
                    <div>Sign Up</div> {this.state.signingup && <Spinner />}
                  </button>
                </div>
              </form>
              <div>
                Already have an account?{" "}
                <Link to={"/login"} className="text-[#8E3A90]">
                  login
                </Link>
              </div>
            </div>
          </div>
          <div>
            <img
              src={SignUpPageImage}
              alt="SignUpPageImage"
              className="w-[45.625rem] h-[55.188rem]"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignupSuccess: state.authentication.isSignupSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { SignupAction })(SignUp)
);
