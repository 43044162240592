import React, { Component } from "react";
import AdminUserPictureSVG from "../../Assets/svg/AdminUserPictureSVG.svg";
import ChevronDownSVG from "../../Assets/svg/ChevronDownSVG.svg";
import ArrowLeftSVG from "../../Assets/svg/ArrowLeftSVG.svg";
import SearchIconSVG from "../../Assets/svg/SearchIconSVG.svg";
import PencilSimpleSVG from "../../Assets/svg/PencilSimpleSVG.svg";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  FetchAllUsersAction,
  BanAndUnbanUserAction,
  FetcIndividualUserAction,
} from "../../Actions/AdminUser";
import DotsThreeSVG from "../../Assets/svg/DotsThreeSVG.svg";

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      showDropDownId: null,
    };
  }

  static propTypes = {
    allUsersData: PropTypes.shape({
      count: PropTypes.number.isRequired,
      next: PropTypes.string,
      previous: PropTypes.string,
      results: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    allUsersLoading: PropTypes.bool.isRequired,
    loadedAllUsersSuccess: PropTypes.bool.isRequired,
    FetchAllUsersAction: PropTypes.func.isRequired,
    BanAndUnbanUserAction: PropTypes.func.isRequired,
    FetcIndividualUserAction: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  handleDropDown = (userId) => {
    this.setState((prevState) => ({
      showDropDownId: prevState.showDropDownId === userId ? null : userId, // Toggle dropdown for the user
    }));
  };

  componentDidMount() {
    this.props.FetchAllUsersAction(this.state.currentPage);
  }

  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      const newPage = this.state.currentPage - 1;
      this.setState({ currentPage: newPage }, () => {
        this.props.FetchAllUsersAction(newPage);
      });
    }
  };

  handleNextPage = () => {
    const totalPages = Math.ceil(this.props.allUsersData.count / 5); // Adjust based on page size
    if (this.state.currentPage < totalPages) {
      const newPage = this.state.currentPage + 1;
      this.setState({ currentPage: newPage }, () => {
        this.props.FetchAllUsersAction(newPage);
      });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.props.FetchAllUsersAction(pageNumber);
    });
  };

  handleEditUserServices = (email) => {
    this.props.FetcIndividualUserAction(email).then(() => {
      this.props.navigate(email);
    });
  };

  handleToggle = (userId) => {
    const { allUsersData } = this.props;
    const user = allUsersData.results.find((user) => user.id === userId);

    if (user) {
      const email = user.email;
      const banned = !user.is_banned; // Toggle the banned status

      // Dispatch the action
      this.props.BanAndUnbanUserAction(email, banned).then(() => {
        // Refresh the user list
        this.props.FetchAllUsersAction(this.state.currentPage);
      });
    }
  };

  // Function to generate page numbers for pagination
  getPageNumbers = () => {
    const { currentPage } = this.state;
    const totalPages = Math.ceil(this.props.allUsersData.count / 5); // Adjust based on page size
    let pageNumbers = [];

    if (totalPages <= 7) {
      // If total pages less than or equal to 7, show all pages
      pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // If total pages more than 7
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      pageNumbers = [1];

      if (startPage > 2) {
        pageNumbers.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  render() {
    const { allUsersData, allUsersLoading } = this.props;

    if (allUsersLoading) {
      return (
        <div className="w-full h-screen flex items-center justify-center">
          Loading...
        </div>
      );
    }

    const pageNumbers = this.getPageNumbers();

    return (
      <div className="w-full h-screen p-[5px] bg-[#D5D7DA]">
        <div className="w-full p-[2rem] h-full rounded-[0.75rem] bg-[#FFFFFF] flex flex-col gap-[1rem]">
          {/* Header Section */}
          <div>
            <div className="flex flex-col gap-[1rem]">
              <img
                src={AdminUserPictureSVG}
                alt="AdminUserPictureSVG"
                className="w-[4.563rem] h-[5.313rem]"
              />
              <div className="font-SFPro font-[700] text-[2.25rem] leading-[2.475rem]">
                Admin & User Management
              </div>
              <div className="font-SFPro font-[400] text-[1.25rem] leading-[1.375rem] text-[#9C9696]">
                Below are the names of admins and users you can manage
              </div>
            </div>
          </div>
          {/* Search Section */}
          <div className="relative w-full flex flex-col gap-[1rem] font-[400] font-inter text-[1.25rem]">
            <div className="relative">
              <input
                className="w-full h-[3.875rem] pl-[3rem] pr-[1rem] border-[1px] border-solid border-[#D9D9D9] rounded-[0.5rem]"
                type="text"
                placeholder="Search for a user"
              />
              <button>
                <img
                  className="absolute top-[50%] left-[1rem] transform -translate-y-1/2 size-[1.5rem]"
                  src={SearchIconSVG}
                  alt="Search Icon"
                />
              </button>
            </div>
          </div>
          {/* Filters Section */}
          <div className="flex w-full gap-[1rem]">
            <div className="flex flex-col gap-[1rem] font-[400] font-inter text-[1.25rem] w-1/2">
              <div className="flex">Filter by Roles</div>
              <div className="flex justify-between items-center border border-solid border-[#D9D9D9] px-[1rem] h-[3.875rem]">
                <div>All Roles</div>
                <img src={ChevronDownSVG} alt="ChevronDownSVG" />
              </div>
            </div>
            <div className="flex flex-col gap-[1rem] font-[400] font-inter text-[1.25rem] w-1/2">
              <div className="flex">Filter by Activity</div>
              <div className="flex justify-between items-center border border-solid border-[#D9D9D9] px-[1rem] h-[3.875rem]">
                <div>All Activity</div>
                <img src={ChevronDownSVG} alt="ChevronDownSVG" />
              </div>
            </div>
          </div>
          {/* Table Header */}
          <div className="font-inter font-[700] text-[1.25rem] grid grid-cols-6 h-[3.125rem] items-center border-b-[1px]">
            <div>#ID</div>
            <div>Name</div>
            <div>Email</div>
            <div>Role</div>
            <div>Status</div>
            <div>Action</div>
          </div>

          {/* User Rows */}
          <div className="flex flex-col overflow-y-scroll font-[400] text-[1rem] font-inter text-[#504A4A]">
            {allUsersData.results.map((user, index) => (
              <div
                key={user.id}
                className={`grid grid-cols-6 p-[1rem] ${
                  index % 2 === 0 ? "bg-[#FFFFFF]" : "bg-[#F9F9F9]"
                }`}
              >
                <div>{user.id}</div>
                <div className="text-wrap">{`${user.first_name || ""} ${
                  user.last_name || ""
                }`}</div>
                <div className="break-words">{user.email}</div>
                <div>
                  {user.is_superuser
                    ? "superuser"
                    : user.is_staff
                    ? "Admin"
                    : "User"}
                </div>
                <div
                  className={`${
                    !user.is_banned
                      ? "bg-[#E7F8DB] text-[#198754] w-fit py-[0.125rem] px-[0.8rem] text-center rounded-[1.188rem]"
                      : "bg-[#F9EAE5] text-[#EC474A] w-fit py-[0.125rem] px-[0.8rem] text-center rounded-[1.188rem]"
                  }`}
                >
                  {!user.is_banned ? "Active" : "Banned"}
                </div>
                <div className="flex gap-[1rem]">
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      id={`switch-${user.id}`}
                      className="toggle-input"
                      checked={!user.is_banned}
                      onChange={() => this.handleToggle(user.id)}
                    />
                    <label
                      htmlFor={`switch-${user.id}`}
                      className="toggle-label"
                    ></label>
                  </div>
                  <div className="relative flex gap-[1rem]">
                    <div>
                      <img
                        onClick={() => this.handleDropDown(user.id)}
                        src={DotsThreeSVG}
                        alt="Toggle Dropdown"
                        className="cursor-pointer"
                      />
                      {this.state.showDropDownId === user.id && (
                        <div className="absolute flex flex-col w-fit h-fit bg-white shadow-md rounded-md z-30">
                          <button
                            onClick={() =>
                              this.handleEditUserServices(user.email)
                            }
                            className="px-[2rem] py-2 text-left hover:bg-gray-100"
                          >
                            Edit
                          </button>
                          <button className="px-[2rem] py-2 text-left hover:bg-gray-100">
                            Promote
                          </button>
                          <button className="px-[2rem] py-2 text-left hover:bg-gray-100">
                            Demote
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination Section */}
          <div className="w-full h-[4.25rem] bg-[#FFFFFF] flex items-center">
            <div className="w-full flex justify-between px-[2.5rem] items-center">
              <button
                onClick={this.handlePreviousPage}
                className="w-[7.188rem] h-[2.25rem] border border-solid border-[#D5D7DA]"
                disabled={this.state.currentPage === 1}
              >
                <div className="flex items-center justify-between px-[0.7rem]">
                  <img
                    className="size-[1.25rem]"
                    src={ArrowLeftSVG}
                    alt="Previous"
                  />
                  <div>Previous</div>
                </div>
              </button>
              <div className="flex gap-[1rem]">
                {pageNumbers.map((pageNumber, index) =>
                  pageNumber === "..." ? (
                    <span
                      key={`ellipsis-${index}`}
                      className="size-[3rem] flex items-center justify-center"
                    >
                      ...
                    </span>
                  ) : (
                    <button
                      key={pageNumber}
                      onClick={() => this.handlePageChange(pageNumber)}
                      className={`size-[3rem] rounded-[50%] ${
                        pageNumber === this.state.currentPage
                          ? "bg-[#F9F5FF]"
                          : ""
                      }`}
                    >
                      {pageNumber}
                    </button>
                  )
                )}
              </div>
              <button
                onClick={this.handleNextPage}
                className="w-[5.5rem] h-[2.25rem] border border-solid border-[#D5D7DA]"
                disabled={
                  this.state.currentPage ===
                  Math.ceil(this.props.allUsersData.count / 5)
                }
              >
                <div className="flex items-center justify-between px-[0.7rem]">
                  <div>Next</div>
                  <img
                    className="rotate-180 size-[1.25rem]"
                    src={ArrowLeftSVG}
                    alt="Next"
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allUsersData: state.adminUser.allUsersData,
  allUsersLoading: state.adminUser.allUsersLoading,
  loadedAllUsersSuccess: state.adminUser.loadedAllUsersSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    FetchAllUsersAction,
    BanAndUnbanUserAction,
    FetcIndividualUserAction,
  })(AdminDashboard)
);
