import React, { Component } from "react";
import ArrowLeftSVG from "../../../Assets/svg/ArrowLeftSVG.svg";
import { Link } from "react-router-dom";
import FileIconSVG from "../../../Assets/svg/FileIconSVG.svg";
import ChartBarSVG from "../../../Assets/svg/ChartBarSVG.svg";

class AIModuleHome extends Component {
  render() {
    return (
      <div className="w-full h-screen">
        <div className="p-[4rem] flex flex-col gap-[2rem]">
          <div className="flex flex-col gap-[1rem]">
            <div className="flex gap-[2rem] items-center">
              <Link to={".."}>
                <img
                  src={ArrowLeftSVG}
                  alt="ArrowLeftSVG"
                  className="w-[1.5rem] h-auto"
                />
              </Link>
              <div className="font-[700] font-SFPro text-[2rem]">
                Select An AI Model
              </div>
            </div>
            <div className="font-[400] font-SFPro text-[1.5rem] pl-[3.5rem]">
              Choose the ai agent you want to interact with
            </div>
          </div>
          <Link to={"ai-chatbot"}>
            <button className="flex w-full flex-col gap-[1rem] rounded-[1.188rem] bg-[#FFFFFF] border border-[#D1D4DA]  pl-[2.313rem] py-[2rem] shadow">
              <div>
                <img
                  src={FileIconSVG}
                  alt="FileIconSVG"
                  className="w-[3.5rem] h-auto"
                />
              </div>

              <div className="h-[8.313rem] w-full flex flex-col gap-[0.5rem] justify-center items-start  ">
                <div className="font-[500] font-inter text-[2rem] w-fit">
                  Enterprise Documents
                </div>
                <div className="font-[400] font-inter text-[1.5rem] w-fit">
                  A model that is tailored to enterprise documents give you all
                  data needed for your day-to-day firm business
                </div>
              </div>
            </button>
          </Link>
          <button className="flex w-full flex-col gap-[1rem] rounded-[1.188rem] bg-[#FFFFFF] border border-[#D1D4DA]  pl-[2.313rem] py-[2rem] shadow">
            <div>
              <img
                src={ChartBarSVG}
                alt="FileIconSVG"
                className="w-[3.5rem] h-auto"
              />
            </div>
            <div className="h-[8.313rem] flex flex-col gap-[0.5rem] justify-center items-start  ">
              <div className="font-[500] font-inter text-[2rem] w-fit">
                Financial Modeling
              </div>
              <div className="font-[400] font-inter text-[1.5rem] w-fit">
                A financial model that gives you real-life and on the demand
                access to stock data
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  }
}
export default AIModuleHome;
