import { combineReducers } from "redux";
import authentication from "./Authentication";
import analysis from "./Analysis";
import ai from "./AI";
import interactions from "./Interactions";
import adminUser from "./AdminUser";
import forms from "./Forms";

export default combineReducers({
  authentication,
  analysis,
  ai,
  interactions,
  adminUser,
  forms,
});
