import React, { Component } from "react";

class UploadCSVComplete extends Component {
  render() {
    return (
      <div className="flex flex-col w-full h-[18.313rem] items-center justify-center font-inter text-[1.5rem] leading-[1.816rem] gap-[2rem] border-[1px] border-[#CC90CB] border-dashed">
        <div className="relative flex items-center justify-center w-[6.125rem] h-[6.125rem]">
          <div className="absolute w-full h-full border-4 border-t-transparent border-b-transparent border-solid border-[#D20C9B] rounded-full animate-spin"></div>
          <div className="flex items-center justify-center bg-white w-[5.125rem] h-[5.125rem] rounded-full z-10">
            100%
          </div>
        </div>
        <div className="flex flex-col gap-[1rem] items-center">
          <div className="">Getting Ready...</div>
        </div>
      </div>
    );
  }
}

export default UploadCSVComplete;
