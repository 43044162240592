import React, { Component } from "react";
import ArrowLeftSVG from "../../../Assets/svg/ArrowLeftSVG.svg";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  renderFileAction,
  FetchIndividualFileAction,
} from "../../../Actions/Analysis";

class PDFExtractorComparator extends Component {
  static propTypes = {
    pdfAnalysisOutput: PropTypes.any,
    renderedPDF: PropTypes.string, // Blob URL of the rendered PDF or CSV file
    contentType: PropTypes.string, // Content type of the file (application/pdf or text/csv)
    PDFisRendered: PropTypes.bool,
    renderFileAction: PropTypes.func.isRequired,
    AddedFileID: PropTypes.string,
    navigate: PropTypes.func.isRequired,
  };

  fetchID = () => {
    const path = this.props.location.pathname;
    const segments = path.split("/");
    const id = segments[segments.length - 2];
    return id;
  };

  componentDidMount() {
    // Dispatch action to render the file if it's not already loaded

    this.props.renderFileAction(this.fetchID());

    this.props.FetchIndividualFileAction(this.fetchID());
  }

  processDataToText = (data, indentLevel = 0) => {
    let output = "";
    const indent = "    ".repeat(indentLevel);

    if (typeof data === "string") {
      try {
        data = JSON.parse(data.replace(/'/g, '"'));
      } catch (e) {
        output += `${indent}${data}\n`;
        return output;
      }
    }

    if (Array.isArray(data)) {
      data.forEach((item) => {
        output += this.processDataToText(item, indentLevel);
      });
    } else if (typeof data === "object" && data !== null) {
      for (const [key, value] of Object.entries(data)) {
        output += `${indent}${key}:\n`;
        output += this.processDataToText(value, indentLevel + 1);
      }
    } else {
      output += `${indent}${data}\n`;
    }

    return output;
  };

  handleBack = () => {
    this.props.navigate(
      `/dashboard/pdf-analyzer/result-summary/${this.fetchID()}/`
    );
  };

  render() {
    const { renderedPDF, contentType, pdfAnalysisOutput } = this.props;

    return (
      <div className="flex flex-col gap-[2rem] w-full h-fit px-[2rem]">
        <img
          src={ArrowLeftSVG}
          alt="Back"
          className="mt-[3rem] cursor-pointer size-[1.5rem]"
          onClick={this.handleBack} // Go back to previous page
        />

        <div className="flex flex-col gap-[1.875rem]">
          <div className=" font-SFPro text-[2rem] font-[700] text-[#8E3B8D]">
            Data Analysis Comparison
          </div>
          <div className="flex w-full gap-[2rem]">
            {/* Original Document Viewer */}
            <div className="w-1/2">
              <div className="font-[500] text-[1.5rem] font-SFPro text-[#535252] text-center">
                Original Data
              </div>
              <div className="w-full h-screen bg-[#F8F8F8] border-solid border-[1px] border-[#E2E0E0]">
                <div className="w-[100%] h-screen bg-[#F8F8F8] border-solid border-[1px] border-[#E2E0E0] flex flex-col">
                  <div className="flex-grow mt-2">
                    {renderedPDF ? (
                      contentType === "application/pdf" ? (
                        <iframe
                          src={renderedPDF}
                          width="100%"
                          height="100%"
                          title="PDF Viewer"
                          frameBorder="0"
                          style={{ display: "block", height: "100%" }} // Inline style for full height
                        />
                      ) : contentType === "text/csv" ? (
                        <div className="csv-download">
                          <a
                            href={renderedPDF}
                            download={`file-${this.props.AddedFileID}.csv`}
                          >
                            Download CSV file
                          </a>
                        </div>
                      ) : (
                        <p>Unsupported file type</p>
                      )
                    ) : (
                      <p>Loading file...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Analysis Output */}
            <div className="w-1/2">
              <div className="font-[500] text-[1.5rem] font-SFPro text-[#535252] text-center">
                Extracted Data
              </div>
              <div className="w-full h-fit bg-[#F8F8F8] border-solid border-[1px] border-[#E2E0E0]">
                <pre className="font-[400] text-[1.75rem] leading-[2.088rem] whitespace-pre-wrap p-[3rem]">
                  {this.processDataToText(pdfAnalysisOutput)}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pdfAnalysisOutput: state.analysis.pdfAnalysisOutput,
  renderedPDF: state.analysis.renderedPDF, // Blob URL from the action
  contentType: state.analysis.contentType, // Content type of the file
  PDFisRendered: state.analysis.PDFisRendered,
  AddedFileID: state.analysis.AddedFileID,
});

const mapDispatchToProps = {
  renderFileAction,
  FetchIndividualFileAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(PDFExtractorComparator));
