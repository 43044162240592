import React, { Component } from "react";
import CSVFileUploader from "./CSVFileUploader";
import UploadFileStatus from "./UploadFileStatus";
import PopUp from "./PopUp";
import UploadCSVFile from "./UploadCSVFile";
import FileUploadMetrics from "./FileUploadMetrics";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import PropTypes from "prop-types";
import { ResetAnalysisReduxStateAction } from "../../../Actions/Analysis";
import { GetUserAction } from "../../../Actions/Authentication";
class DashboardHome extends Component {
  static propTypes = {
    csvFileUploadSuccess: PropTypes.bool,
    ResetAnalysisReduxStateAction: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    GetUserAction: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.ResetAnalysisReduxStateAction();
    this.props.GetUserAction();
  }

  handlePath = () => {
    let text = "";
    if (this.props.location.pathname === "/dashboard") {
      text = "SurveyIQ Upload Metrics";
    } else if (this.props.location.pathname === "/dashboard/pdf-analyzer") {
      text = "SmartExtract Upload Metrics";
    }

    return text;
  };

  render() {
    return (
      <div className="relative flex max-w-[71.625rem] border-l">
        {this.props.csvFileUploadSuccess && <PopUp />}
        <div className="w-full flex flex-col px-[3rem] pt-[3rem] pb-[3rem] gap-[2rem]">
          <div className="flex flex-col w-[66.188rem] gap-[1.5rem]">
            <div className="text-black font-SFPro font-[700] text-[2rem] leading-[2.387rem]">
              {this.handlePath()}
            </div>
            <FileUploadMetrics />
          </div>
          <CSVFileUploader />
          <UploadFileStatus />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  csvFileUploadSuccess: state.analysis.csvFileUploadSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { ResetAnalysisReduxStateAction, GetUserAction })(
    DashboardHome
  )
);
