import React, { Component } from "react";
import UserProfilePicturePlaceHolderJPEG from "../../../../Assets/jpeg/UserProfilePicturePlaceHolderJPEG.jpg";
import SettingsIconSVG from "../../../../Assets/svg/SettingsIconSVG.svg";
import CollapseWindowBottonSVG from "../../../../Assets/svg/CollapseWindowBottonSVG.svg";
import CESSVG from "../../../../Assets/svg/CESSVG.svg";
import DotsThreeSVG from "../../../../Assets/svg/DotsThreeSVG.svg";
import ChatTeardropDotsSVG from "../../../../Assets/svg/ChatTeardropDotsSVG.svg";
import {
  FetchChatSessionsAction,
  FetchIndividualChatSessionAction,
  ContinueChatSessionAddSessionIDToReduxAction,
  resetContinueChatSessionAction,
  DeleteChatSessionAction,
  ResetIsNewChatSession,
} from "../../../../Actions/AI";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import PropTypes from "prop-types";
import TrashSVG from "../../../../Assets/svg/TrashSVG.svg";
import { CollapseCX2SidebarInactiveAction } from "../../../../Actions/Interactions";

class AIChatBotSideBar extends Component {
  static propTypes = {
    FetchChatSessionsAction: PropTypes.func.isRequired,
    FetchIndividualChatSessionAction: PropTypes.func.isRequired,
    ContinueChatSessionAddSessionIDToReduxAction: PropTypes.func.isRequired,
    SideBarchatSessions: PropTypes.array,
    SideBarchatSessionsFetchSuccess: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
    resetContinueChatSessionAction: PropTypes.func.isRequired,
    ResetIsNewChatSession: PropTypes.func.isRequired,
    DeleteChatSessionAction: PropTypes.func.isRequired,
    user: PropTypes.object,
    toggleSidebar: PropTypes.func.isRequired,
    CollapseCX2SidebarInactiveAction: PropTypes.func.isRequired,
  };

  state = {
    chatsessionID: null,
    showOptions: false,
    activeMenuId: null,
  };

  componentDidMount() {
    // Fetch chat sessions when the component mounts
    this.props.FetchChatSessionsAction();
  }

  handleContinueChat = (sessionId) => {
    this.props.FetchIndividualChatSessionAction(sessionId);
    this.props.ContinueChatSessionAddSessionIDToReduxAction(sessionId);
    this.props.navigate(`/dashboard/ai-models/ai-chatbot/${sessionId}`);
    this.props.ResetIsNewChatSession();
  };

  handleStartNewChat = () => {
    this.props.resetContinueChatSessionAction();
    this.props.navigate("/dashboard/ai-models/ai-chatbot");
  };

  handleDeleteChatSession = (sessionId) => {
    // Extract the ID from the current path
    const currentPathId = this.props.location?.pathname?.split("/").pop();

    // Call the delete action and wait for it to complete
    this.props.DeleteChatSessionAction(sessionId).then(() => {
      // Fetch updated chat sessions after deletion
      this.props.FetchChatSessionsAction();

      // Clear the active menu state
      this.setState({ activeMenuId: null });

      // If the current path matches the session being deleted, navigate to a new path
      if (currentPathId === sessionId.toString()) {
        this.props.navigate("/dashboard/ai-chatbot");
      }
    });
  };

  toggleMenu = (sessionId) => {
    // Toggle the menu visibility for the specific session
    this.setState((prevState) => ({
      activeMenuId: prevState.activeMenuId === sessionId ? null : sessionId,
    }));
  };

  handleCollapseDisable = () => {
    this.props.CollapseCX2SidebarInactiveAction();
  };

  renderChatSessions() {
    const { SideBarchatSessions, SideBarchatSessionsFetchSuccess } = this.props;

    if (!SideBarchatSessionsFetchSuccess) {
      return <div>Loading...</div>;
    }

    if (!SideBarchatSessions || SideBarchatSessions.length === 0) {
      return <div>No chat sessions available.</div>;
    }

    // Sort sessions by started_at timestamp in descending order (most recent first)
    const sortedSessions = [...SideBarchatSessions].sort(
      (a, b) => new Date(b.started_at) - new Date(a.started_at)
    );

    return sortedSessions.map((session) => {
      // Format the start date using JavaScript's toLocaleString
      const formattedDate = new Date(session.started_at).toLocaleString(
        "en-US",
        {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      );

      // Find the last message sent by the user
      const userMessages = session.messages.filter(
        (message) => message.role === "user"
      );
      const lastUserMessage =
        userMessages.length > 0
          ? userMessages[userMessages.length - 1].content // Corrected index
          : "No user messages yet";

      // Trim the user message to 30 characters with ellipsis if it's too long
      const trimmedMessage =
        lastUserMessage.length > 30
          ? lastUserMessage.substring(0, 30) + "..."
          : lastUserMessage;

      return (
        <div
          key={session.id}
          className="flex justify-between items-center gap-2 mb-4 p-2 bg-[#F3F3EE] rounded-md relative"
        >
          <button
            onClick={() => this.handleContinueChat(session.id)}
            className="flex flex-col flex-grow text-left"
          >
            <div className="font-semibold text-sm">{formattedDate}</div>
            <div className="text-gray-600 text-sm">{trimmedMessage}</div>
          </button>

          {/* Three-dot button to toggle delete menu */}
          <button
            onClick={() => this.toggleMenu(session.id)}
            className="relative"
          >
            <img src={DotsThreeSVG} alt="more options" />
          </button>

          {/* Menu with delete button positioned near the three-dot icon */}
          {this.state.activeMenuId === session.id && (
            <div className="absolute right-8 top-0 bg-white border shadow-lg rounded-md p-2 z-[999]">
              <button
                onClick={() => this.handleDeleteChatSession(session.id)}
                className="flex items-center gap-2 text-red-600"
              >
                <img src={TrashSVG} alt="Delete" />
                <span>Delete</span>
              </button>
            </div>
          )}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="fixed flex flex-col shrink-0 w-[19.188rem] h-screen justify-center items-center bg-[#F3F3EE] border-r z-20 border-none">
        <div className="flex w-[16.563rem] mt-[2.75rem] h-screen flex-col bg-[#F3F3EE] gap-[2rem]">
          <div className="flex flex-col gap-[1.875rem]">
            {/* Header with Logo and Collapse Button */}
            <div className="flex justify-between">
              <img src={CESSVG} alt="CES svg logo" />
              <button onClick={this.handleCollapseDisable}>
                <img src={CollapseWindowBottonSVG} alt="Collapse" />
              </button>
            </div>

            {/* User Profile Section */}
            <div className="flex justify-between">
              <div className="flex gap-[0.5rem] items-center">
                <img
                  src={UserProfilePicturePlaceHolderJPEG}
                  alt="user profile picture"
                />
                <div className="font-[500] font-inter text-[1.125rem]">
                  {this.props.user?.first_name} {this.props.user?.last_name}
                </div>
              </div>
              <button>
                <img src={SettingsIconSVG} alt="Settings Icon" />
              </button>
            </div>
          </div>

          {/* Chat Sessions */}
          <div className="flex flex-col gap-[1.875rem]">
            <div className="">
              <div className="font-[600] font-inter text-[1.125rem]">
                Chat Sessions
              </div>
              <div className="flex flex-col gap-[0.438rem] h-fit max-h-[25rem] overflow-x-hidden overflow-y-scroll">
                {this.renderChatSessions()}
              </div>
            </div>

            {/* Start New Chat Button */}
            <button
              onClick={this.handleStartNewChat}
              className="flex justify-center items-center bg-gradient-to-r w-full h-[3.125rem] from-[#5FA731] to-[#4D8F22] rounded-[0.438rem] gap-2"
            >
              <img src={ChatTeardropDotsSVG} alt="Start new chat" />
              <div className="font-[500] font-inter text-[1.125rem] text-[#F9F9F9]">
                Start New Chat
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  SideBarchatSessions: state.ai.SideBarchatSessions,
  SideBarchatSessionsFetchSuccess: state.ai.SideBarchatSessionsFetchSuccess,
  user: state.authentication.user,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    FetchChatSessionsAction,
    FetchIndividualChatSessionAction,
    ContinueChatSessionAddSessionIDToReduxAction,
    resetContinueChatSessionAction,
    DeleteChatSessionAction,
    ResetIsNewChatSession,
    CollapseCX2SidebarInactiveAction,
  })(AIChatBotSideBar)
);
