import React from "react";

export function Void2() {
  return (
    <div className="shrink-0 w-[18.7rem] h-full ">
      <div className="w-full max-h-screen flex items-center justify-center text-white">
        Void2
      </div>
    </div>
  );
}

export default Void2;
