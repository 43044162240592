import React, { Component } from "react";
import HomeSVG from "../../Assets/svg/HouseSVG.svg";
import CSVSVG from "../../Assets/svg/CSVSVG.svg";
import CESSVG from "../../Assets/svg/CESSVG.svg";
import LogoutSVG from "../../Assets/svg/LogoutSVG.svg";
import { LogoutAction } from "../../Actions/Authentication";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import CESRobotSVG from "../../Assets/svg/CESRobotSVG.svg";
import SettingGearSVG from "../../Assets/svg/SettingGearSVG.svg";
import FilePdfSVG from "../../Assets/svg/FilePdfSVG.svg";
import FilesBlackSVG from "../../Assets/svg/FilesBlackSVG.svg";
import { Link } from "react-router-dom";
import WhiteFileCsvSVG from "../../Assets/svg/WhiteFileCsvSVG.svg";
import WhiteFilePdfSVG from "../../Assets/svg/WhiteFilePdfSVG.svg";
import EnvelopeIconSVG from "../../Assets/svg/EnvelopeIconSVG.svg";
import PaintBucketSVG from "../../Assets/svg/PaintBucketSVG.svg";
import AIGenerateIconDarkSVG from "../../Assets/svg/AssistGenAILogoSVG.svg";

class DashboardSidebar extends Component {
  static propTypes = {
    LogoutAction: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  handlePath = () => {
    const { pathname } = this.props.location;
    if (
      pathname === "/dashboard" ||
      pathname.includes("/dashboard/result-summary")
    ) {
      return "dashboard";
    }
    if (
      pathname.includes("/dashboard/pdf-analyzer") ||
      pathname.includes("/dashboard/pdf-analyzer/result-summary") ||
      pathname.includes("/dashboard/pdf-analyzer/result-summary/compare")
    ) {
      return "pdf-analyzer";
    }
    return null;
  };

  handleLogout = () => {
    this.props.LogoutAction();
  };

  handleCollapseDisable = () => {
    this.props.CollapseCX2SidebarActiveAction();
  };

  render() {
    const currentPath = this.handlePath();

    return (
      <div className="fixed flex flex-col shrink-0 w-[16.625rem] h-screen items-center justify-between gap-[4rem] bg-white border-r z-20">
        <div className="flex flex-col mt-[3rem] gap-[2rem] overflow-scroll">
          <div className="w-[4.438rem]">
            <img src={CESSVG} alt="CESSVG" className="w-full h-auto" />
          </div>
          <div className="flex flex-col gap-[1.625rem] w-[12.5rem] h-fit">
            <Link to={"/dashboard"}>
              <button
                className={`w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] border-none flex items-center ${
                  currentPath === "dashboard"
                    ? "bg-[#8E3B8D] text-white"
                    : "text-black"
                }`}
              >
                {currentPath === "dashboard" ? (
                  <img src={WhiteFileCsvSVG} alt="CSV Analyzer" />
                ) : (
                  <img src={CSVSVG} alt="Home" />
                )}
                <div>SurveyIQ</div>
              </button>
            </Link>

            <Link to={"/dashboard/pdf-analyzer"}>
              <button
                className={`w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] border-none flex items-center ${
                  currentPath === "pdf-analyzer"
                    ? "bg-[#8E3B8D] text-white"
                    : "text-black"
                }`}
              >
                {currentPath === "pdf-analyzer" ? (
                  <img src={WhiteFilePdfSVG} alt="CSV Analyzer" />
                ) : (
                  <img src={FilePdfSVG} alt="Home" />
                )}
                <div>SmartExtract</div>
              </button>
            </Link>
            <Link to={"/dashboard/smartflow"}>
              <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black">
                <img src={PaintBucketSVG} alt="CES Bot" />
                <div>SmartFlow</div>
              </button>
            </Link>
            <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black">
              <img src={FilesBlackSVG} alt="Recent Files" />
              <div>Recent Files</div>
            </button>
            <Link to={"/dashboard/ai-chatbot"}>
              <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black">
                <img src={CESRobotSVG} alt="CES Bot" />
                <div>CX2</div>
              </button>
            </Link>
            <Link to={"/dashboard/ai-models"}>
              <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black">
                <img src={AIGenerateIconDarkSVG} alt="CES Bot" />
                <div>Assist Gen AI</div>
              </button>
            </Link>
            <Link to={"/dashboard/message-overview"}>
              <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black">
                <img src={EnvelopeIconSVG} alt="CES Messaging" />
                <div>Messages</div>
              </button>
            </Link>
            <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black">
              <img src={SettingGearSVG} alt="Settings" />
              <div>Settings</div>
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-[1.625rem] w-[12.5rem] h-fit mb-[2rem]">
          <button
            onClick={this.handleLogout}
            className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black"
          >
            <img className="size-[1.5rem]" src={LogoutSVG} alt="Logout" />
            <div>Logout</div>
          </button>
        </div>
      </div>
    );
  }
}

export default withRouterHooks(
  connect(null, { LogoutAction })(DashboardSidebar)
);
