import React, { useState } from "react";
import SmartFlowWelcomeDialogue from "./SmartFlowWelcomeDialogue";
import SmartFlowMoreInformationDialogue from "./SmartFlowMoreInformationDialogue";
import SmartFlowInputs from "./SmartFlowInputs";

const SmartFlowPopUp = () => {
  const components = [
    {
      component: <SmartFlowWelcomeDialogue key="welcome" />,
    },
    {
      component: <SmartFlowMoreInformationDialogue key="moreInfo" />,
    },
    {
      component: <SmartFlowInputs key="inputs" />,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false); // Manage the transition state

  const handleNext = () => {
    if (currentIndex < components.length - 1) {
      setIsTransitioning(true); // Start the transition
      setTimeout(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1); // Move to the next component
        setIsTransitioning(false); // Reset the transition state
      }, 800); // Transition duration (matches CSS slide-out time)
    }
  };

  return (
    <div
      className="fixed w-full h-screen inset-0 flex items-center justify-center bg-[#2a2929] bg-opacity-50 backdrop-blur-sm z-50"
      onClick={handleNext} // Advance to the next component on click
    >
      <div
        className={`w-full flex justify-center ${
          isTransitioning ? "slide-out" : "slide-in"
        }`}
      >
        {components[currentIndex].component}
      </div>
    </div>
  );
};

export default SmartFlowPopUp;
