import React from "react";

export function Void3() {
  return (
    <div className="shrink-0 w-[16.5rem] bg-[#F9F9F9]">
      <div className="w-full max-h-screen flex items-center justify-center text-white"></div>
    </div>
  );
}

export default Void3;
