import React, { Component } from "react";
import CESSVG from "../../../Assets/svg/CESSVG.svg";
import { Link } from "react-router-dom";
import OverViewIconDarkSVG from "../../../Assets/svg/OverViewIconDarkSVG.svg";
import WhatsappIconSVG from "../../../Assets/svg/WhatsappIconSVG.svg";
import EmailIconSVG from "../../../Assets/svg/EmailIconSVG.svg";
import PhoneCallIconSVG from "../../../Assets/svg/PhoneCallIconSVG.svg";
import VoicemailIconSVG from "../../../Assets/svg/VoicemailIconSVG.svg";

class MessagingSideBar extends Component {
  render() {
    return (
      <div className="fixed flex flex-col shrink-0 w-[16.625rem] h-screen items-center justify-between gap-[4rem] bg-white border-r z-20">
        <div className="flex flex-col mt-[3rem] gap-[2rem] overflow-scroll">
          <div className="w-[4.438rem]">
            <img src={CESSVG} alt="CESSVG" className="w-full h-auto" />
          </div>
          <div className="flex flex-col gap-[1.625rem] w-[12.5rem] h-fit">
            <Link to={"/dashboard/message-overview"}>
              <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black font-[400] font-inter text-[1.25rem]">
                <img src={OverViewIconDarkSVG} alt="Home" />
                <div>Overview</div>
              </button>
            </Link>
            <Link to={"/dashboard/message-overview/whatsapp"}>
              <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black font-[400] font-inter text-[1.25rem]">
                <img src={WhatsappIconSVG} alt="Recent Files" />
                <div>Whatsapp</div>
              </button>
            </Link>

            <Link to={"/dashboard/message-overview/email"}>
              <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black font-[400] font-inter text-[1.25rem]">
                <img src={EmailIconSVG} alt="CES Messaging" />
                <div>Email</div>
              </button>
            </Link>
            <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black font-[400] font-inter text-[1.25rem]">
              <img src={PhoneCallIconSVG} alt="Settings" />
              <div>Calls</div>
            </button>
            <button className="w-full h-[2.125rem] pl-[1rem] gap-[0.3rem] flex items-center text-black font-[400] font-inter text-[1.25rem]">
              <img src={VoicemailIconSVG} alt="Settings" />
              <div>Voice Mail</div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default MessagingSideBar;
