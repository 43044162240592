// NewChat.js

import React, { Component } from "react";
import CESSVG from "../../../../Assets/svg/CESSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import { resetContinueChatSessionAction } from "../../../../Actions/AI";
import { GetUserAction } from "../../../../Actions/Authentication";

class NewChat extends Component {
  static propTypes = {
    resetContinueChatSessionAction: PropTypes.func.isRequired,
    GetUserAction: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.props.resetContinueChatSessionAction();
    this.props.GetUserAction();
  }

  render() {
    return (
      <div className="flex-col relative w-[51.438rem] h-[20.188rem] bg-white rounded-lg flex items-center justify-center gap-[1.063rem] mb-[6rem]">
        <div className="flex flex-col w-fit h-[12rem] items-center gap-[1rem]">
          {/* <img className="w-[113px] h-[58px]" src={CESSVG} alt="CES logo" /> */}
          <div className="font-[700] font-SFPro text-[4rem] w-fit">
            Welcome To <span className="text-[#A12F9F]">Genni ai</span>{" "}
            {/* {this.props.user?.first_name} {this.props.user?.last_name} */}
          </div>
          <div className="font-[500] font-SFPro text-[1.5rem]">
            How can I assist you?
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    resetContinueChatSessionAction,
    GetUserAction,
  })(NewChat)
);
