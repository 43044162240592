import React, { Component } from "react";
import FIleProcessedSuccessSVG from "../../Assets/svg/FIleProcessedSuccessSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../withRouters/withRoutersHook";

class AuthenticationMessageDispatcher extends Component {
  static propTypes = {
    message: PropTypes.string,
  };
  render() {
    return (
      <div className="flex left-[62rem] top-[2.563rem] w-[25.438rem] h-[4.438rem] justify-center items-center bg-white border border-red-light shadow-custom-shadow rounded-[0.5rem]">
        <div className="flex gap-[0.625rem] w-fit h-fit items-center">
          <img src={FIleProcessedSuccessSVG} alt="File Processed Success" />
          <div className="font-[700] text-[1rem] font-SFPro text-red-600">
            {this.props.message}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.authentication.message,
});

export default withRouterHooks(
  connect(mapStateToProps, {})(AuthenticationMessageDispatcher)
);
