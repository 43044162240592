import React, { Component } from "react";
import PlusSignSVG from "../../../Assets/svg/PlusSignSVG.svg";
import SendIconSVGDark from "../../../Assets/svg/SendIconSVGDark.svg";
import AIGenerateIconDarkSVG from "../../../Assets/svg/AIGenerateIconDarkSVG.svg";

class ChatInput extends Component {
  render() {
    return (
      <div className="absolute bottom-[0rem] w-full bg-[#EBEAEA] h-[6.625rem] flex  items-center justify-center rounded-b-[9px]">
        <div className="flex w-full px-[1rem] gap-[0.5rem] justify-evenly">
          <button>
            <img src={PlusSignSVG} alt="Plus Sign" />
          </button>
          <button>
            <img src={AIGenerateIconDarkSVG} alt="Send Icon" />
          </button>
          <input
            className="w-[80%] bg-[#FFFFFF] rounded-[14px] focus:outline-none pl-[2rem]"
            type="text"
            placeholder=""
          />
          <button>
            <img src={SendIconSVGDark} alt="Send Icon" />
          </button>
        </div>
      </div>
    );
  }
}

export default ChatInput;
