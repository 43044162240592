import React, { Component } from "react";
import ArrowLeftSVG from "../../../Assets/svg/ArrowLeftSVG.svg";
import { Link } from "react-router-dom";
import SmartFlowPopUp from "./SmartFlowPopUp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import {
  FetchFormTypesAction,
  CreateFormInstanceAction,
  AddFormInstanceIDToReduxAction,
  ResetFormReduxStateAction,
} from "../../../Actions/Forms";

class SmartflowHome extends Component {
  static propTypes = {
    formTypes: PropTypes.array,
    FetchFormTypesAction: PropTypes.func.isRequired,
    CreateFormInstanceAction: PropTypes.func.isRequired,
    formInstance: PropTypes.object,
    formInstanceID: PropTypes.number,
    ResetFormReduxStateAction: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.FetchFormTypesAction();
    this.props.ResetFormReduxStateAction();
  }

  handCreateFormInstance = (formType) => {
    this.props.CreateFormInstanceAction(formType).then((data) => {
      this.props.AddFormInstanceIDToReduxAction(data.id, data.form);
    });
  };

  render() {
    const { formTypes } = this.props;

    return (
      <div className="w-full h-screen">
        {this.props.formInstance && <SmartFlowPopUp />}
        <div className="p-[4rem] flex flex-col gap-[2rem]">
          <div className="flex flex-col gap-[1rem]">
            <div className="flex gap-[2rem] items-center">
              <Link to={".."}>
                <img
                  src={ArrowLeftSVG}
                  alt="ArrowLeftSVG"
                  className="w-[1.5rem] h-auto"
                />
              </Link>
              <div className="font-[700] font-SFPro text-[2rem]">
                Select Form Type
              </div>
            </div>
            <div className="font-[400] font-SFPro text-[1.5rem] pl-[3.5rem]">
              Easily fill documents and download results
            </div>
          </div>
          {formTypes?.map((formType, index) => (
            <button
              onClick={() => this.handCreateFormInstance(formType.id)}
              className="flex flex-col gap-[1rem]"
            >
              <div
                key={index}
                className="h-[8.313rem] w-full flex flex-col gap-[0.5rem] justify-center items-start pl-[2.313rem] rounded-[1.188rem] bg-[#EBEAEA] border border-[#5D5D5D]"
              >
                <div className="font-[500] font-inter text-[2rem] w-fit">
                  {formType.name || "NA"}
                </div>
                <div className="font-[400] font-inter text-[1.5rem] w-fit">
                  {formType.description || "NA"}
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  formTypes: state.forms.formTypes,
  formInstance: state.forms.formInstance,
  formInstanceID: state.forms.formInstanceID,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    FetchFormTypesAction,
    CreateFormInstanceAction,
    AddFormInstanceIDToReduxAction,
    ResetFormReduxStateAction,
  })(SmartflowHome)
);
