import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import {
  FetchFormStructureAction,
  UpdateFormInstanceAction,
  ResetFormReduxStateAction,
} from "../../../Actions/Forms";
import CancelButtonSVG from "../../../Assets/svg/CancelButtonSVG.svg";
import CompletCheckMarkSVG from "../../../Assets/svg/CompletCheckMarkSVG.svg";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";

const SmartFlowInputs = ({
  formStructure,
  fetchFormStructure,
  formInstanceID,
  updateFormInstance,
  ResetFormReduxStateAction,
  navigate,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (formInstanceID) {
      fetchFormStructure(formInstanceID).then((data) => {
        if (data && data.values) {
          setFormData(data.values);
        }
      });
    }
  }, [fetchFormStructure, formInstanceID]);

  if (!formStructure || !formStructure.values) {
    return <div>Loading...</div>;
  }

  const fieldKeys = Object.keys(formStructure.values);

  if (fieldKeys.length === 0) {
    return <div>No fields found</div>;
  }

  const currentFieldKey = fieldKeys[currentStep];
  const currentValue = formData[currentFieldKey];

  const isBooleanField =
    currentFieldKey &&
    currentFieldKey.startsWith("(") &&
    currentFieldKey.endsWith(")");

  const handleChange = (e) => {
    const value = isBooleanField ? e.target.checked : e.target.value;
    setFormData((prev) => ({ ...prev, [currentFieldKey]: value }));
  };

  const handleNext = async () => {
    if (currentStep < fieldKeys.length - 1) {
      const updatedField = { [currentFieldKey]: formData[currentFieldKey] };
      try {
        setIsSubmitting(true);
        await updateFormInstance(formInstanceID, updatedField);
        setCurrentStep((prevStep) => prevStep + 1);
      } catch (error) {
        console.error("Error updating form instance:", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await updateFormInstance(formInstanceID, formData);
      setIsCompleted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleExitForm = () => {
    ResetFormReduxStateAction();
  };

  const handlePreview = () => {
    navigate(`preview/${formInstanceID}`);
  };

  if (isCompleted) {
    return (
      <div className="w-[90%] h-[28.938rem] flex flex-col justify-center items-center bg-[#FFFFFF] rounded-[1rem] gap-[1rem] px-[4rem] py-[2rem]">
        <img
          className="size-[7.875rem]"
          src={CompletCheckMarkSVG}
          alt="Completion Checkmark"
        />
        <div className="font-[700] font-inter text-[2rem] flex flex-col gap-[1.5rem]">
          <div className="flex items-center gap-[1rem]">
            <div className="font-[500] font-inter text-[1.625rem]">
              You have successfully updated your form.
            </div>
          </div>
          <button
            className="font-[700] font-inter text-[1.5rem] w-full h-[4.25rem] bg-[#8E3B8D] text-white rounded-[0.5rem]"
            onClick={handlePreview}
          >
            Preview Form
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-[90%] h-[28.938rem] flex flex-col justify-center items-center bg-[#FFFFFF] rounded-[1rem] gap-[1rem] px-[4rem] py-[2rem]">
      <div className="w-full">
        <button onClick={handleExitForm}>
          <img
            className="size-[2rem]"
            src={CancelButtonSVG}
            alt="Cancel Button"
          />
        </button>
      </div>
      <div className="font-[700] font-inter text-[2rem] flex flex-col gap-[1.5rem] w-full">
        <div className="flex justify-between items-center mb-[1.5rem]">
          <div>
            {isBooleanField
              ? "Please Confirm This Option"
              : `What Is ${formatFieldLabel(currentFieldKey)}`}
          </div>
          <div className="font-[300] text-[1.5rem]">
            Step {Math.min(currentStep + 1, fieldKeys.length)}/
            {fieldKeys.length}
          </div>
        </div>

        {isBooleanField ? (
          <div className="flex items-center gap-[1rem] w-full">
            <input
              type="checkbox"
              className="w-[2rem] h-[2rem] rounded border-2 border-[#8E3B8D] bg-white focus:outline-none checked:bg-[#8E3B8D] checked:border-[#8E3B8D] appearance-none transition duration-200 ease-in-out"
              checked={!!currentValue}
              onChange={handleChange}
            />
            <label className="font-[500] font-inter text-[1.625rem]">
              {`${formatFieldLabel(currentFieldKey)}`}
            </label>
          </div>
        ) : (
          <input
            type="text"
            placeholder={`Enter ${formatFieldLabel(currentFieldKey)}`}
            value={currentValue || ""}
            onChange={handleChange}
            className="font-[500] font-inter text-[1.25rem] text-[#5D5D5D] w-full h-[4.375rem] rounded-[0.5rem] border-[1px] border-[#5D5D5D] pl-[0.5rem] focus:border-[#8E3B8D] focus:outline-none transition duration-200 ease-in-out placeholder-gray-400"
          />
        )}

        <div className="flex justify-between w-full mt-[1rem]">
          <button
            onClick={handleBack}
            disabled={currentStep === 0}
            className={`font-[700] font-inter text-[2rem] w-[48%] h-[4.25rem] bg-[#D9D9D9] text-black rounded-[0.5rem] ${
              currentStep === 0 && "cursor-not-allowed"
            }`}
          >
            Back
          </button>
          <button
            onClick={handleNext}
            disabled={isSubmitting}
            className={`font-[700] font-inter text-[2rem] w-[48%] h-[4.25rem] bg-[#8E3B8D] text-white rounded-[0.5rem] ${
              isSubmitting
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-[#7a2a7a] transition"
            }`}
          >
            {isSubmitting
              ? "Submitting..."
              : currentStep === fieldKeys.length - 1
              ? "Finish"
              : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

// Helper function to format field labels
const formatFieldLabel = (fieldKey) => {
  if (!fieldKey) return ""; // Return empty string for invalid keys
  const cleanedKey = fieldKey.replace(/^\(|\)$/g, ""); // Remove parentheses
  return cleanedKey
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

SmartFlowInputs.propTypes = {
  formStructure: PropTypes.object.isRequired,
  fetchFormStructure: PropTypes.func.isRequired,
  formInstanceID: PropTypes.number,
  updateFormInstance: PropTypes.func.isRequired,
  ResetFormReduxStateAction: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  formStructure: state.forms.formStructure,
  formInstanceID: state.forms.formInstanceID,
});

const mapDispatchToProps = {
  fetchFormStructure: FetchFormStructureAction,
  updateFormInstance: UpdateFormInstanceAction,
  ResetFormReduxStateAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterHooks(SmartFlowInputs));
