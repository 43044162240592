import React, { Component } from "react";
import ArrowLeftSVG from "../../Assets/svg/ArrowLeftSVG.svg";
import InActiveStateSVG from "../../Assets/svg/InActiveStateSVG.svg";
import ActiveStateSVG from "../../Assets/svg/ActiveStateSVG.svg";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  FetcIndividualUserAction,
  grantAccessToServicesAction,
} from "../../Actions/AdminUser";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import { connect } from "react-redux";

class AdminUserPermissionChanger extends Component {
  static propTypes = {
    fetchUserSuccess: PropTypes.bool,
    loadingUser: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    lastLogin: PropTypes.string,
    isBanned: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    isSubscribed: PropTypes.bool,
    hasAccessToSmartIQ: PropTypes.bool,
    hasAccessToSmartExtract: PropTypes.bool,
    hasAccessToAI: PropTypes.bool,
    hasAccessToMessaging: PropTypes.bool,
    FetcIndividualUserAction: PropTypes.func.isRequired,
    grantAccessToServicesAction: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSmartIQActive: props.hasAccessToSmartIQ || false,
      isSmartExtractActive: props.hasAccessToSmartExtract || false,
      isCX2BOTActive: props.hasAccessToAI || false,
      isMessagingActive: props.hasAccessToMessaging || false,
      isSubscribed: props.isSubscribed || false,
    };
  }

  componentDidMount() {
    const email = this.extractEmailFromPath();
    this.props.FetcIndividualUserAction(email);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hasAccessToSmartIQ !== this.props.hasAccessToSmartIQ) {
      this.setState({
        isSmartIQActive: this.props.hasAccessToSmartIQ,
      });
    }
    if (
      prevProps.hasAccessToSmartExtract !== this.props.hasAccessToSmartExtract
    ) {
      this.setState({
        isSmartExtractActive: this.props.hasAccessToSmartExtract,
      });
    }
    if (prevProps.hasAccessToAI !== this.props.hasAccessToAI) {
      this.setState({
        isCX2BOTActive: this.props.hasAccessToAI,
      });
    }
    if (prevProps.hasAccessToMessaging !== this.props.hasAccessToMessaging) {
      this.setState({
        isMessagingActive: this.props.hasAccessToMessaging,
      });
    }
    if (prevProps.isSubscribed !== this.props.isSubscribed) {
      this.setState({
        isSubscribed: this.props.isSubscribed,
      });
    }
  }

  extractEmailFromPath = () => {
    const path = this.props.location.pathname;
    const segments = path.split("/").filter(Boolean);
    return segments[segments.length - 1];
  };

  handleServiceToggle = (serviceKey, apiKey) => {
    const email = this.extractEmailFromPath();
    const newValue = !this.state[serviceKey];

    this.setState(
      {
        [serviceKey]: newValue,
      },
      () => {
        const data = {
          email: email,
          [apiKey]: newValue,
        };

        this.props
          .grantAccessToServicesAction(data)
          .then(() => {
            this.props.FetcIndividualUserAction(email);
          })
          .catch((error) => {
            console.error("Error updating service access:", error);
            // Optionally revert state if needed
            this.setState({
              [serviceKey]: !newValue,
            });
          });
      }
    );
  };

  render() {
    const {
      fetchUserSuccess,
      firstName,
      lastName,
      email,
      lastLogin,
      isSuperuser,
      isAdmin,
    } = this.props;

    return (
      <div className="w-full h-screen px-[4rem] py-[2rem]">
        <div className="w-full h-full flex flex-col gap-[2rem]">
          {/* Header Section */}
          <div className="flex flex-col gap-[0.25rem]">
            <div className="flex gap-[1rem] items-center">
              <Link to={"/admin-dashboard"}>
                <img
                  className="size-[2rem]"
                  src={ArrowLeftSVG}
                  alt="Arrow back"
                />
              </Link>
              <div className="font-[700] text-[2rem] font-SFPro text-[#302D2D] leading-[2.2rem] w-full flex justify-between items-center">
                <div>User Service Management</div>
                <button
                  onClick={() =>
                    this.handleServiceToggle("isSubscribed", "is_subscribed")
                  }
                  className="flex items-center pr-[3rem] gap-[2rem] border border-solid px-[1rem] py-[0.5rem] border-[#fff] hover:border-[#22C55E] rounded-[4px]"
                >
                  <div className="font-[700] text-[2rem] font-SFPro leading-[2.2rem]">
                    {this.state.isSubscribed
                      ? "Is subscribed"
                      : "Not subscribed"}
                  </div>
                  {this.state.isSubscribed ? (
                    <img className="size-[2rem]" src={ActiveStateSVG} alt="" />
                  ) : (
                    <img
                      className="size-[2rem]"
                      src={InActiveStateSVG}
                      alt=""
                    />
                  )}
                </button>
              </div>
            </div>
            <div className="relative left-[3rem] font-[400] font-SFPro text-[#504A4A] text-[1.5rem] leading-[1.65rem] w-fit">
              Manage Service for{" "}
              {fetchUserSuccess
                ? `${firstName || "NA"} ${lastName || "NA"}`
                : "NA"}
            </div>
          </div>

          {/* User Details Section */}
          <div className="flex flex-col px-[3rem] py-[1.375rem] gap-[2.125rem] border-[0.25rem] border-solid border-[#D9D9D9] rounded-[3px] ">
            <div className="font-[700] text-[1.5rem] font-SFPro leading-[1.65rem]">
              User Details
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col gap-[0.313rem]">
                <div className="font-[400] font-SFPro text-[1rem] leading-[1.1rem] text-[#D9D9D9]  hover:border-[#22C55E] rounded-[3px]">
                  Username
                </div>
                <div className="font-[600] font-inter text-[1.25rem] leading-[1.375rem]">
                  {fetchUserSuccess
                    ? `${firstName || "NA"} ${lastName || "NA"}`
                    : "NA"}
                </div>
              </div>
              <div>
                <div className="font-[400] font-SFPro text-[1rem] leading-[1.1rem] text-[#D9D9D9]  hover:border-[#22C55E] rounded-[3px]">
                  Email
                </div>
                <div className="font-[600] font-inter text-[1.25rem] leading-[1.375rem]">
                  {fetchUserSuccess ? `${email || "NA"}` : "NA"}
                </div>
              </div>
              <div>
                <div className="font-[400] font-SFPro text-[1rem] leading-[1.1rem] text-[#D9D9D9]  hover:border-[#22C55E] rounded-[3px]">
                  Role
                </div>
                <div className="font-[600] font-inter text-[1.25rem] leading-[1.375rem]">
                  {isSuperuser ? "Superuser" : isAdmin ? "Admin" : "User"}
                </div>
              </div>
              <div>
                <div className="font-[400] font-SFPro text-[1rem] leading-[1.1rem] text-[#D9D9D9]  hover:border-[#22C55E] rounded-[3px]">
                  Last Active
                </div>
                <div className="font-[600] font-inter text-[1.25rem] leading-[1.375rem]">
                  {fetchUserSuccess && lastLogin
                    ? `${Math.floor(
                        (new Date() - new Date(lastLogin)) / (1000 * 60)
                      )} minutes ago`
                    : "NA"}
                </div>
              </div>
            </div>
          </div>

          {/* Services Section */}
          <div className="h-[500px] overflow-y-scroll px-[0.5rem] pb-[2rem] pt-[1rem]">
            <div className="grid grid-cols-3 gap-[2.625rem]">
              {/* Survey IQ Service */}
              <button
                onClick={() =>
                  this.handleServiceToggle(
                    "isSmartIQActive",
                    "has_access_to_smart_iq"
                  )
                }
                className={`p-[2rem] flex flex-col gap-[1.5rem] ${
                  this.state.isSmartIQActive || this.state.isSubscribed
                    ? "border-[0.25rem] border-solid border-[#22C55E]"
                    : "border-[0.25rem] border-solid border-[#D1D4DA]"
                } hover:border-[#22C55E] rounded-[4px]`}
              >
                <div className="flex w-full justify-between items-center">
                  <div className="font-[700] text-[2rem] font-SFPro leading-[2.2rem]">
                    Survey IQ
                  </div>
                  {this.state.isSmartIQActive || this.state.isSubscribed ? (
                    <img className="size-[2rem]" src={ActiveStateSVG} alt="" />
                  ) : (
                    <img
                      className="size-[2rem]"
                      src={InActiveStateSVG}
                      alt=""
                    />
                  )}
                </div>
                <div className="font-[500] font-inter text-[1.5rem] leading-[2.235rem] text-[#504A4A]">
                  Survey analytics processor and results!
                </div>
                <div className="flex gap-[0.625rem] items-center w-fit">
                  <div
                    className={`size-[1rem] rounded-[50%] ${
                      this.state.isSmartIQActive || this.state.isSubscribed
                        ? "bg-[#22C55E]"
                        : "bg-[#D1D4DA]"
                    } relative left-[3.5px]`}
                  ></div>
                  <div className=" font-inter font-[400] text-[1.25rem] leading-[1.863rem]">
                    {this.state.isSmartIQActive || this.state.isSubscribed
                      ? "Active"
                      : "Inactive"}
                  </div>
                </div>
              </button>

              {/* SmartExtract Service */}
              <button
                onClick={() =>
                  this.handleServiceToggle(
                    "isSmartExtractActive",
                    "has_access_to_smart_extract"
                  )
                }
                className={`p-[2rem] flex flex-col gap-[1.5rem] ${
                  this.state.isSmartExtractActive || this.state.isSubscribed
                    ? "border-[0.25rem] border-solid border-[#22C55E]"
                    : "border-[0.25rem] border-solid border-[#D1D4DA]"
                } hover:border-[#22C55E] rounded-[4px]`}
              >
                <div className="flex w-full justify-between items-center">
                  <div className="font-[700] text-[2rem] font-SFPro leading-[2.2rem]">
                    SmartExtract
                  </div>
                  {this.state.isSmartExtractActive ||
                  this.state.isSubscribed ? (
                    <img className="size-[2rem]" src={ActiveStateSVG} alt="" />
                  ) : (
                    <img
                      className="size-[2rem]"
                      src={InActiveStateSVG}
                      alt=""
                    />
                  )}
                </div>
                <div className="font-[500] font-inter text-[1.5rem] leading-[2.235rem] text-[#504A4A]">
                  Analyzing PDF data and giving results!
                </div>
                <div className="flex gap-[0.625rem] items-center w-fit">
                  <div
                    className={`size-[1rem] rounded-[50%] ${
                      this.state.isSmartExtractActive || this.state.isSubscribed
                        ? "bg-[#22C55E]"
                        : "bg-[#D1D4DA]"
                    } relative left-[3.5px]`}
                  ></div>
                  <div className=" font-inter font-[400] text-[1.25rem] leading-[1.863rem]">
                    {this.state.isSmartExtractActive || this.state.isSubscribed
                      ? "Active"
                      : "Inactive"}
                  </div>
                </div>
              </button>

              {/* CX2BOT Service */}
              <button
                onClick={() =>
                  this.handleServiceToggle("isCX2BOTActive", "has_access_to_ai")
                }
                className={`p-[2rem] flex flex-col gap-[1.5rem] ${
                  this.state.isCX2BOTActive || this.state.isSubscribed
                    ? "border-[0.25rem] border-solid border-[#22C55E]"
                    : "border-[0.25rem] border-solid border-[#D1D4DA]"
                } hover:border-[#22C55E] rounded-[4px]`}
              >
                <div className="flex w-full justify-between items-center">
                  <div className="font-[700] text-[2rem] font-SFPro leading-[2.2rem]">
                    CX2BOT
                  </div>
                  {this.state.isCX2BOTActive || this.state.isSubscribed ? (
                    <img className="size-[2rem]" src={ActiveStateSVG} alt="" />
                  ) : (
                    <img
                      className="size-[2rem]"
                      src={InActiveStateSVG}
                      alt=""
                    />
                  )}
                </div>
                <div className="font-[500] font-inter text-[1.5rem] leading-[2.235rem] text-[#504A4A]">
                  More high-value analytics than Survey IQ
                </div>
                <div className="flex gap-[0.625rem] items-center w-fit">
                  <div
                    className={`size-[1rem] rounded-[50%] ${
                      this.state.isCX2BOTActive || this.state.isSubscribed
                        ? "bg-[#22C55E]"
                        : "bg-[#D1D4DA]"
                    } relative left-[3.5px]`}
                  ></div>
                  <div className=" font-inter font-[400] text-[1.25rem] leading-[1.863rem]">
                    {this.state.isCX2BOTActive || this.state.isSubscribed
                      ? "Active"
                      : "Inactive"}
                  </div>
                </div>
              </button>

              {/* Messaging Service */}
              <button
                onClick={() =>
                  this.handleServiceToggle(
                    "isMessagingActive",
                    "has_access_to_messaging"
                  )
                }
                className={`p-[2rem] flex flex-col gap-[1.5rem] ${
                  this.state.isMessagingActive || this.state.isSubscribed
                    ? "border-[0.25rem] border-solid border-[#22C55E]"
                    : "border-[0.25rem] border-solid border-[#D1D4DA]"
                } hover:border-[#22C55E] rounded-[4px]`}
              >
                <div className="flex w-full justify-between items-center">
                  <div className="font-[700] text-[2rem] font-SFPro leading-[2.2rem] max-w-[70%] text-left">
                    Message Management
                  </div>
                  {this.state.isMessagingActive || this.state.isSubscribed ? (
                    <img className="size-[2rem]" src={ActiveStateSVG} alt="" />
                  ) : (
                    <img
                      className="size-[2rem]"
                      src={InActiveStateSVG}
                      alt=""
                    />
                  )}
                </div>
                <div className="font-[500] font-inter text-[1.5rem] leading-[2.235rem] text-[#504A4A]">
                  Manage your messages effectively!
                </div>
                <div className="flex gap-[0.625rem] items-center w-fit">
                  <div
                    className={`size-[1rem] rounded-[50%] ${
                      this.state.isMessagingActive || this.state.isSubscribed
                        ? "bg-[#22C55E]"
                        : "bg-[#D1D4DA]"
                    } relative left-[3.5px]`}
                  ></div>
                  <div className=" font-inter font-[400] text-[1.25rem] leading-[1.863rem]">
                    {this.state.isMessagingActive || this.state.isSubscribed
                      ? "Active"
                      : "Inactive"}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchUserSuccess: state.adminUser.fetchUserSuccess,
  loadingUser: state.adminUser.loadingUser,
  firstName: state.adminUser.firstName,
  lastName: state.adminUser.lastName,
  email: state.adminUser.email,
  lastLogin: state.adminUser.lastLogin,
  isBanned: state.adminUser.isBanned,
  isAdmin: state.adminUser.isAdmin,
  isSuperuser: state.adminUser.isSuperuser,
  isSubscribed: state.adminUser.isSubscribed,
  hasAccessToSmartIQ: state.adminUser.hasAccessToSmartIQ,
  hasAccessToSmartExtract: state.adminUser.hasAccessToSmartExtract,
  hasAccessToAI: state.adminUser.hasAccessToAI,
  hasAccessToMessaging: state.adminUser.hasAccessToMessaging,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    FetcIndividualUserAction,
    grantAccessToServicesAction,
  })(AdminUserPermissionChanger)
);
