import React, { Component } from "react";
import ArrowLeftSVG from "../../../Assets/svg/ArrowLeftSVG.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import PropTypes from "prop-types";
import {
  RenderForm,
  FetchFormStructureAction,
  ResetFormReduxStateAction,
  SaveFormInstanceAsPDF,
} from "../../../Actions/Forms";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

class SmartflowPreview extends Component {
  static propTypes = {
    RenderForm: PropTypes.func.isRequired,
    renderFilledForm: PropTypes.string,
    formInstanceID: PropTypes.number,
    formStructure: PropTypes.string,
    FetchFormStructureAction: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  getFormInstanceID = () => {
    const path = this.props.location.pathname;
    const segments = path.split("/");
    return segments[segments.length - 1];
  };

  componentDidMount() {
    const { RenderForm, formInstanceID, FetchFormStructureAction } = this.props;
    RenderForm(this.getFormInstanceID());
    FetchFormStructureAction(this.getFormInstanceID());
    console.log(this.getFormInstanceID());
  }

  handleDownloadAsPDF = () => {
    const container = document.querySelector(".rendered-form-container");

    if (!container) {
      console.error("Rendered form container not found.");
      return;
    }

    // Initialize jsPDF instance
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4",
    });

    // Get the page dimensions
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Use html2canvas to capture the content
    html2canvas(container, {
      scale: 2, // Increase resolution for better quality
      useCORS: true, // Handle cross-origin images
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Calculate scaling to fit content to the page
      const imgWidth = pageWidth - 40; // Padding of 20px on each side
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let yPosition = 20; // Initial y position

      // Add content to PDF, splitting into pages if needed
      doc.addImage(imgData, "PNG", 20, yPosition, imgWidth, imgHeight);

      if (imgHeight > pageHeight) {
        // Split into multiple pages
        let remainingHeight = imgHeight - pageHeight;

        while (remainingHeight > 0) {
          yPosition = remainingHeight - imgHeight + 20; // Adjust y position for the new page
          doc.addPage();
          doc.addImage(
            imgData,
            "PNG",
            20,
            yPosition,
            imgWidth,
            imgHeight - remainingHeight
          );
          remainingHeight -= pageHeight;
        }
      }

      // Save the PDF
      doc.save("form-preview.pdf");
    });
  };

  handleExitForm = () => {
    const { ResetFormReduxStateAction } = this.props;
    ResetFormReduxStateAction();
  };

  handleBackToSmartflow = () => {
    const { navigate, ResetFormReduxStateAction } = this.props;
    ResetFormReduxStateAction();
    navigate("/dashboard/smartflow");
  };

  handleSaveForm = () => {
    const { SaveFormInstanceAsPDF } = this.props;
    SaveFormInstanceAsPDF(this.getFormInstanceID());
    this.handleBackToSmartflow();
  };

  render() {
    const { renderFilledForm, formStructure } = this.props;

    // Check if formStructure is null or undefined
    if (!formStructure) {
      return (
        <div className="min-h-screen w-full bg-gray-100 flex items-center justify-center py-8 px-4">
          <div className="text-gray-500 text-center">
            Loading form structure...
          </div>
        </div>
      );
    }

    return (
      <div className="min-h-screen w-full bg-gray-100 flex items-center justify-center py-8 px-4">
        <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6 flex flex-col gap-[2rem]">
          {/* Header */}
          <div className="flex flex-col items-center gap-4 mb-6">
            <div className="flex gap-[1rem] items-center">
              <Link to="/dashboard/smartflow">
                <img
                  onClick={this.handleExitForm}
                  src={ArrowLeftSVG}
                  alt="Go Back"
                  className="w-6 h-6 cursor-pointer"
                />
              </Link>
              <h1 className="text-2xl font-bold font-SFPro text-gray-800">
                {formStructure.form || "Form Preview"}
              </h1>
            </div>
            <div className="font-[400] font-SFPro text-[1.5rem]">
              Please don't forget to hit Send Document at the bottom to send the
              document.
            </div>
          </div>

          {/* Form Preview Container */}
          {renderFilledForm ? (
            <div
              className="rendered-form-container border border-gray-200 p-4 rounded-md overflow-auto"
              dangerouslySetInnerHTML={{ __html: renderFilledForm }}
            />
          ) : (
            <div className="text-gray-500 text-center">Loading form...</div>
          )}

          {/* Action Buttons */}
          <div className="flex justify-end gap-4">
            <button
              onClick={this.handleDownloadAsPDF}
              className="w-40 h-12 bg-gray-200 text-gray-800 font-semibold text-lg border border-gray-300 rounded-md hover:bg-gray-300 transition"
            >
              Download PDF
            </button>
            <button
              onClick={() => this.handleSaveForm()}
              className="w-40 h-12 bg-green-600 text-white font-semibold text-lg rounded-md hover:bg-green-700 transition"
            >
              Send Doc
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  formInstanceID: state.forms.formInstanceID,
  renderFilledForm: state.forms.renderFilledForm,
  formStructure: state.forms.formStructure,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    RenderForm,
    FetchFormStructureAction,
    ResetFormReduxStateAction,
    SaveFormInstanceAsPDF,
  })(SmartflowPreview)
);
