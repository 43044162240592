import axios from "./Axios";
import {
  CSV_FILE_UPLOAD_SUCCESS,
  CSV_FILE_UPLOAD_FAIL,
  FILE_UPLOAD_TRIGGERED_SUCCESS,
  RESET_ANALYSIS_REDUX_STATE,
  FETCH_CSV_SUCCESS,
  FETCH_CSV_FAIL,
  DELETE_CSV_SUCCESS,
  DELETE_CSV_FAIL,
  CSV_ANALYSIS_SUCCESS,
  CSV_ANALYSIS_FAIL,
  SENT_TO_MAIL,
  RENDER_ON_SITE,
  ADD_ANALYSIS_FILE_NAMES_TO_REDUX,
  RESET_ANALYSIS_MESSAGE_REDUX_STATE,
  FETCH_INDIVIDUAL_FILE_SUCCESS,
  FETCH_INDIVIDUAL_FILE_FAIL,
  RENDER_PDF_FILE_SUCCESS,
  RENDER_PDF_FILE_FAIL,
  ADD_ANALYSIS_FILE_ID_TO_REDUX,
  FAKE_CSV_ANALYSIS_SUCCESS,
} from "./Types";
import { returnErrors } from "./Errors";

export const UploadCSVFileAction = (file) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const body = new FormData();
  body.append("file", file);

  try {
    const res = await axios.post(`/api/analytics/upload-csv/`, body, config);

    dispatch({
      type: CSV_FILE_UPLOAD_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    console.error("File upload failed:", err.response?.data || err.message);

    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: CSV_FILE_UPLOAD_FAIL,
    });
    throw err;
  }
};

export const TriggerFileUploadAction = () => (dispatch) => {
  dispatch({ type: FILE_UPLOAD_TRIGGERED_SUCCESS });
};

export const ResetAnalysisReduxStateAction = () => (dispatch) => {
  dispatch({ type: RESET_ANALYSIS_REDUX_STATE });
};

export const FetchCSVAction = () => (dispatch) => {
  return axios
    .get(`/api/analytics/user-files/`)
    .then((res) => {
      dispatch({
        type: FETCH_CSV_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: FETCH_CSV_FAIL,
      });
    });
};

export const DeleteCSVAction = (id) => (dispatch) => {
  axios
    .delete(`/api/analytics/delete-csv/${id}/`)
    .then((res) => {
      dispatch({
        type: DELETE_CSV_SUCCESS,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: DELETE_CSV_FAIL,
      });
    });
};

export const ResetDeleteCSVReduxStateAction = () => (dispatch) => {
  dispatch({ type: DELETE_CSV_FAIL });
};

export const SendCSVForAnalysisAction = (id) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/analytics/send-csv-to-api/${id}/`);

    dispatch({
      type: CSV_ANALYSIS_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));

    dispatch({
      type: CSV_ANALYSIS_FAIL,
    });
  }
};

export const SendToMail = () => (dispatch) => {
  dispatch({
    type: SENT_TO_MAIL,
  });
};

export const RenderOnSite = () => (dispatch) => {
  dispatch({
    type: RENDER_ON_SITE,
  });
};

export const AddAnalysisFileNamesToRedux = (fileNames) => (dispatch) => {
  dispatch({
    type: ADD_ANALYSIS_FILE_NAMES_TO_REDUX,
    payload: fileNames,
  });
};

export const ResetAnalysisMessageAction = () => (dispatch) => {
  dispatch({
    type: RESET_ANALYSIS_MESSAGE_REDUX_STATE,
  });
};

export const FetchIndividualFileAction = (id) => (dispatch) => {
  return axios
    .get(`/api/analytics/fetch-individual-file/${id}/`)
    .then((res) => {
      dispatch({
        type: FETCH_INDIVIDUAL_FILE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })

    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: FETCH_INDIVIDUAL_FILE_FAIL,
      });
    });
};

export const renderFileAction = (fileId) => (dispatch) => {
  return axios
    .get(`/api/analytics/download-csv/${fileId}/`, {
      responseType: "blob", // Fetches the file as a blob
    })
    .then((res) => {
      const url = URL.createObjectURL(res.data); // Create an object URL from the blob
      dispatch({
        type: RENDER_PDF_FILE_SUCCESS,
        payload: { url, contentType: res.headers["content-type"] }, // Store URL and content type
      });
    })
    .catch((err) => {
      dispatch({
        type: RENDER_PDF_FILE_FAIL,
        payload: err.message,
      });
    });
};

export const AddAnalysisFileIDToRedux = (id) => (dispatch) => {
  dispatch({
    type: ADD_ANALYSIS_FILE_ID_TO_REDUX,
    payload: id,
  });
};

export const FakeAnalysisReduxSuccessAction = () => (dispatch) => {
  dispatch({
    type: FAKE_CSV_ANALYSIS_SUCCESS,
  });
};
