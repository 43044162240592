import React, { Component } from "react";
import ArrowLeftSVG from "../../../Assets/svg/ArrowLeftSVG.svg";
import PaperPlaneRightSVG from "../../../Assets/svg/PaperPlaneRightSVG.svg";
import FilterIconSVG from "../../../Assets/svg/FilterIconSVG.svg";
import MagnifyingGlassSVG from "../../../Assets/svg/MagnifyingGlassSVG.svg";
import DotsThreeSVG from "../../../Assets/svg/DotsThreeSVG.svg";
import Customize from "./Customize";
import { Link } from "react-router-dom";

class Messaging extends Component {
  state = {
    activeCustomizeIndex: null, // Tracks the dropdown for a specific item
  };

  handleCustomize = (index) => {
    // Toggle dropdown for the selected item
    this.setState((prevState) => ({
      activeCustomizeIndex:
        prevState.activeCustomizeIndex === index ? null : index,
    }));
  };

  closeCustomize = () => {
    this.setState({ activeCustomizeIndex: null });
  };

  render() {
    const messages = [
      {
        name: "Sarah Thomas",
        message: "How are you doing today?",
        response: "I’m doing very well thank you.",
        status: "New",
        platform: "Via Whatsapp",
      },
      {
        name: "Jake Book",
        message: "My complaint hasn’t been resolved",
        response: "Sorry about that we are actively working on it",
        status: "Pending",
        platform: "Via Email",
      },
      {
        name: "Sabastian Ego",
        message: "Is it possible I send in documents?",
        response: "Of course you can, also do that in 48hrs.",
        status: "New",
        platform: "Via Whatsapp",
      },
      {
        name: "Jason Todd",
        message: "My complaint hasn’t been resolved",
        response: "Sorry about that we are actively working on it",
        status: "Pending",
        platform: "Via Email",
      },
      {
        name: "James Garrison",
        message: "My complaint hasn’t been resolved",
        response: "Sorry about that we are actively working on it",
        status: "Pending",
        platform: "Via Email",
      },
    ];

    return (
      <div className="w-full h-screen bg-[#F9F9F9] p-[1rem]">
        <div className="w-full h-full bg-[#FFFFFF] px-[2rem] py-[3rem] rounded-[9px] overflow-y-auto">
          <div className="w-full h-full flex flex-col gap-[2rem]">
            {/* Header */}
            <div className="w-full flex justify-between">
              <Link to={"/dashboard"}>
                <div className="flex gap-[1rem] text-[2.5rem] font-SFPro font-[700]">
                  <img src={ArrowLeftSVG} alt="Arrow Left" />
                  <div>All Messages</div>
                </div>
              </Link>
              <button className="flex items-center gap-[1rem] px-[2rem] rounded-[3.188rem] bg-[#A12F9F] text-white">
                <img src={PaperPlaneRightSVG} alt="Paper Plane Right" />
                <div className="text-[1rem] font-[700] font-SFPro">
                  Send Broadcast
                </div>
              </button>
            </div>

            {/* Search and Filter */}
            <div className="flex w-full justify-between gap-[1rem] relative">
              <img
                className="absolute top-[1.2rem] left-[1rem] z-20 cursor-pointer"
                src={MagnifyingGlassSVG}
                alt="Search Icon"
              />
              <input
                type="text"
                placeholder="Search for a customer"
                className="w-full py-4 pl-[4rem] font-SFPro text-[1.5rem] font-[400] border border-[#DDD9D9] focus:border-[#A12F9F] outline-none rounded-[7px] shadow-sm"
              />
              <button className="flex justify-center items-center w-[5.313rem] h-[4.5rem] border border-[#DDD9D9] rounded-[7px]">
                <img src={FilterIconSVG} alt="Filter Icon" />
              </button>
            </div>

            {/* Messages Table Header */}
            <div>
              <div className="grid grid-cols-5 font-SFPro font-[500] text-[1.5rem] py-[1.5rem] px-[2.5rem] bg-[#F1F2F3] border-[1px] border-[#D1D4DA] gap-[0.5rem]">
                <div className="w-fit break-words">Customer Name</div>
                <div className="w-fit break-words">Message</div>
                <div className="relative left-[5rem] w-fit break-words">
                  AI Response
                </div>
                <div className="relative left-[10rem] w-fit break-words">
                  Status
                </div>
                <div className="relative left-[5rem] w-fit break-words">
                  Actions
                </div>
              </div>

              {/* Messages Rows */}
              <div className="flex flex-col">
                {messages.map((item, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-5 font-inter font-[400] text-[1.375rem] py-[1.5rem] px-[2.5rem] bg-[#FFFFFF] gap-[0.5rem] border-[1px] border-x-[#D1D4DA] border-b-[#D1D4DA]"
                  >
                    <div>
                      <div>{item.name}</div>
                      <div className="font-[300] text-[1rem] font-inter text-[#5D5D5D]">
                        {item.platform}
                      </div>
                    </div>
                    <div>{item.message}</div>
                    <div className="relative left-[5rem]">{item.response}</div>
                    <div
                      className={`relative left-[10rem] w-fit break-words ${
                        item.status === "New"
                          ? "text-[#3366CC]"
                          : "text-[#EC474A]"
                      }`}
                    >
                      {item.status}
                    </div>
                    <div className="relative flex justify-center items-center gap-[1rem]">
                      {/* Accept Button */}
                      <button className="left-[5rem] break-words w-[9.375rem] h-[2.313rem] bg-[#198754] rounded-[49px] text-white">
                        Accept
                      </button>

                      {/* Dots Menu Button */}
                      <div className="relative">
                        <button
                          onClick={() => this.handleCustomize(index)}
                          className="p-2"
                        >
                          <img src={DotsThreeSVG} alt="Customize Options" />
                        </button>

                        {/* Render Customize for Active Item */}
                        {this.state.activeCustomizeIndex === index && (
                          <div className="absolute top-[2.5rem] right-0">
                            <Customize closeCustomize={this.closeCustomize} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex w-full justify-between items-center">
              <button className="flex justify-center items-center px-[2rem] py-[1rem] gap-[0.5rem] border border-[#D5D7DA]">
                <img
                  className="flex size-[1rem]"
                  src={ArrowLeftSVG}
                  alt="Arrow Left"
                />
                <div className="font-[400] font-inter text-[1rem]">
                  Previous
                </div>
              </button>
              <div>
                <button className="size-[2.5rem] font-inter font-[500] text-[1.25rem] bg-[#8E3B8D] text-white rounded-[8px]">
                  1
                </button>
                <button className="size-[2.5rem] font-inter font-[500] text-[1.25rem]">
                  2
                </button>
                <button className="size-[2.5rem] font-inter font-[500] text-[1.25rem]">
                  3
                </button>
                <button className="size-[2.5rem] font-inter font-[500] text-[1.25rem]">
                  ...
                </button>
                <button className="size-[2.5rem] font-inter font-[500] text-[1.25rem]">
                  8
                </button>
                <button className="size-[2.5rem] font-inter font-[500] text-[1.25rem]">
                  9
                </button>
                <button className="size-[2.5rem] font-inter font-[500] text-[1.25rem]">
                  10
                </button>
              </div>
              <button className="flex justify-center items-center px-[2rem] py-[1rem] gap-[0.5rem] border border-[#D5D7DA]">
                <div>Next</div>
                <img
                  className="flex rotate-180 size-[1rem]"
                  src={ArrowLeftSVG}
                  alt="Arrow Left"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Messaging;
