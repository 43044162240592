import React, { Component } from "react";
import AccountCreatedSuccessSVG from "../../Assets/svg/AccountCreatedSuccessSVG.svg";

class AccountCreated extends Component {
  render() {
    return (
      <div className="flex absolute w-full h-screen bg-black bg-opacity-50 backdrop-blur-sm justify-center items-center z-50">
        <div className="flex gap-[1rem] flex-col w-[35.188rem] h-[16.563rem] bg-white justify-center items-center">
          <img
            src={AccountCreatedSuccessSVG}
            alt="AccountCreatedSuccessSVG"
            className="w-[5.25rem] h-[5.018rem]"
          />
          <div className="font-[600] font-inter text-[1.5rem] leading-[1.438rem] w-[22.813rem] text-center">
            You have successfully created an account with CES analytics
          </div>
        </div>
      </div>
    );
  }
}
export default AccountCreated;
