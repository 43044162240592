import React, { Component } from "react";
import CSVProcessingOnSiteFailedSVG from "../../../Assets/svg/CSVProcessingOnSiteFailedSVG.svg";

class CSVProcessingOnSiteFailed extends Component {
  render() {
    return (
      <div className="flex flex-col gap-[2rem] w-[44.938rem] h-[29.375rem] bg-white justify-center rounded-lg items-center z-100">
        <img
          src={CSVProcessingOnSiteFailedSVG}
          alt="AccountCreatedSuccessSVG"
          className="w-[9.688rem] h-[9.688rem]"
        />
        <div className="font-[600] font-inter text-[1.5rem] w-[35.75rem] text-center leading-[1.828rem]">
          An Error has occured while trying to import your csv file, please try
          again.
        </div>
      </div>
    );
  }
}

export default CSVProcessingOnSiteFailed;
