import React, { Component } from "react";
import CancelButtonSVG from "../../../Assets/svg/CancelButtonSVG.svg";
import ChevronRightSVG from "../../../Assets/svg/ChevronRightSVG.svg";
import AIGenerateIconSVG from "../../../Assets/svg/AIGenerateIconSVG.svg";

class Customize extends Component {
  render() {
    const { closeCustomize } = this.props;
    return (
      <div className="absolute top-[-1rem] left-[-22.5rem] w-[15.375rem] mx-auto bg-white py-[1rem] border rounded shadow z-50">
        <div className="flex flex-col gap-[0.313rem]">
          <div className="flex font-inter font-[600] text-[1.125rem] w-full justify-between px-[1rem]">
            <div>Customize</div>
            <button onClick={closeCustomize}>
              <img src={CancelButtonSVG} />
            </button>
          </div>
          <div className="w-full h-[1px] bg-[#D9D9D9]"></div>
          <div className="flex flex-col gap-[0.313rem] px-[1rem]">
            <div className="flex w-full justify-between items-center">
              <div className="font-inter font-[500] text-[1rem]">
                Change Tone
              </div>
              <div className="flex gap-[0.5rem]">
                <div className="font-inter font-[500] text-[0.75rem] px-[1rem] py-[0.5rem] rounded-[1rem] bg-[#D9D9D9]">
                  Formal
                </div>
                <img src={ChevronRightSVG} />
              </div>
            </div>
            <div className="flex w-full justify-between items-center">
              <div className="font-inter font-[500] text-[1rem]">Length</div>
              <div className="flex gap-[0.5rem]">
                <div className="font-inter font-[500] text-[0.75rem] px-[1rem] py-[0.5rem] rounded-[1rem] bg-[#D9D9D9]">
                  Short
                </div>
                <img src={ChevronRightSVG} />
              </div>
            </div>
            <button className="flex w-full bg-[#8E3B8D] text-white justify-center items-center gap-[1rem] font-inter font-[500] text-[0.875rem] rounded-[0.25rem]">
              <div>Generate</div>
              <img src={AIGenerateIconSVG} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Customize;
