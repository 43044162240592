import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import CESSVG from "../../Assets/svg/CESSVG.svg";
import ChangePasswordImage from "../../Assets/jpeg/ForgotPasswordOverLay.jpg";
import ArrowLeftSVG from "../../Assets/svg/ArrowLeftSVG.svg";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { VerifyEmailAction } from "../../Actions/Authentication";
import AccountCreated from "./AccountCreatedSuccessMessage";

class VerifyEmail extends Component {
  state = {
    otp: "",
    displayAccountCreated: false,
  };

  static propTypes = {
    VerifyEmailAction: PropTypes.func.isRequired,
    email: PropTypes.string,
    navigate: PropTypes.func.isRequired,
    isVerified: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.inputsRef = Array(4) // Create an array of refs for the OTP inputs
      .fill(0)
      .map(() => createRef());
  }

  // Handle form submission
  onSubmit = (e) => {
    e.preventDefault();
    const { email } = this.props;
    const { otp } = this.state;

    if (!otp || otp.length < 4) {
      alert("Please enter a 4-digit code.");
      return;
    }

    // Call the VerifyEmailAction with email and OTP
    this.props.VerifyEmailAction(email, otp);
  };

  // Handle OTP input change and update the state
  handleInput = (e, index) => {
    const { value } = e.target;

    if (value && index < this.inputsRef.length - 1) {
      this.inputsRef[index + 1].current.focus();
    }

    // Update the OTP state with the value entered
    let newOtp = this.state.otp.split("");
    newOtp[index] = value;
    this.setState({ otp: newOtp.join("") });
  };

  handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !e.target.value) {
      // If Backspace is pressed on an empty field, move to the previous input
      this.inputsRef[index - 1].current.focus();
    }
  };

  componentDidUpdate(prevProps) {
    // Check if isVerified has changed from false to true
    if (
      this.props.isVerified &&
      this.props.isVerified !== prevProps.isVerified
    ) {
      this.setState({ displayAccountCreated: true });
      //redirect in 3 seconds
      setTimeout(() => {
        this.props.navigate("/login");
      }, 3000);
    }
  }

  render() {
    const { email } = this.props;
    return (
      <div className="flex w-full h-screen justify-center items-center">
        {this.state.displayAccountCreated && <AccountCreated />}
        <div className="flex flex-row">
          <div className="flex items-start pt-[1rem] justify-center w-[32.063rem] h-[55.188rem]">
            <div className="flex flex-col justify-center items-center w-[27.313rem] gap-[3rem]">
              <div className="flex flex-col gap-[6rem] w-full">
                <div className="w-[4.438rem] h-[1.925rem]">
                  <img src={CESSVG} alt="CESSVG" className="w-full h-full" />
                </div>
                <div className="flex flex-col">
                  <div className="font-SFPro font-[700] text-[2.5rem] relative right-0">
                    Verify Email
                  </div>
                  <div className="font-SFPro font-[400] text-[1.25rem] text-[#443F3F]">
                    We sent a code to{" "}
                    <span className="font-[600] text-[1.1rem]">{email}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[1rem]">
                <div className="flex space-x-6">
                  {this.inputsRef.map((ref, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      ref={ref}
                      onChange={(e) => this.handleInput(e, index)}
                      onKeyDown={(e) => this.handleKeyDown(e, index)}
                      className="size-[4.063rem] border-2 border-gray-300 rounded-md text-center text-xl focus:outline-none focus:border-[#8E3A90] focus:ring-2 focus:ring-[#8E3A90] transition"
                    />
                  ))}
                </div>
              </div>
              <div className="w-full">
                <button
                  onClick={this.onSubmit}
                  className="w-full h-[3.75rem] bg-[#7EC14D] text-white text-[600] font-inter text-[1.5rem]"
                >
                  Continue
                </button>
              </div>
              <div className="font-inter font-[400] text-[1.125rem]">
                Didn’t receive the email?{" "}
                <span className="font-[600]">click to resend</span>
              </div>
              <Link to={"/login"} className="flex gap-[1rem] items-center">
                <div className="w-[2rem]">
                  <img src={ArrowLeftSVG} alt="arrow left svg" />
                </div>
                <div className="text-[#443F3F] text-[1.125rem] font-inter font-[400]">
                  Back to login
                </div>
              </Link>
            </div>
          </div>
          <div>
            <img
              src={ChangePasswordImage}
              alt="SignUpPageImage"
              className="w-[45.625rem] h-[55.188rem]"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.authentication.email,
  isVerified: state.authentication.isVerified,
});

export default withRouterHooks(
  connect(mapStateToProps, { VerifyEmailAction })(VerifyEmail)
);
