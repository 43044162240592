import { string } from "prop-types";
import axios from "./Axios";
import { returnErrors } from "./Errors";
import {
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAIL,
  BAN_USER_SUCCESS,
  BAN_USER_FAIL,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  SERVICE_ACCESS_GRANTED_SUCCESS,
  SERVICE_ACCESS_GRANTED_FAIL,
} from "./Types";

export const FetchAllUsersAction =
  (id = 1) =>
  async (dispatch) => {
    try {
      const res = await axios.get(`/api/admin-user/list-all-users/?page=${id}`);
      dispatch({
        type: FETCH_ALL_USERS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: FETCH_ALL_USERS_FAIL,
      });
    }
  };

export const BanAndUnbanUserAction = (email, banned) => async (dispatch) => {
  try {
    const res = await axios.post("/api/admin-user/update-ban-status/", {
      email,
      banned,
    });
    dispatch({
      type: BAN_USER_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: BAN_USER_FAIL,
    });
  }
};

export const FetcIndividualUserAction = (email) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin-user/fetch-user/${email}`);

    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: FETCH_USER_FAIL,
    });
  }
};

export const grantAccessToServicesAction = (data) => async (dispatch) => {
  try {
    const res = await axios.post("/api/admin-user/service-access/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({
      type: SERVICE_ACCESS_GRANTED_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    console.error("API Error:", err.response); // For debugging
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: SERVICE_ACCESS_GRANTED_FAIL,
    });
  }
};
