import {
  COLLAPSE_CX2_SIDEBAR_ACTIVE,
  COLLAPSE_CX2_SIDEBAR_INACTIVE,
} from "./Types";

export const CollapseCX2SidebarActiveAction = () => {
  return {
    type: COLLAPSE_CX2_SIDEBAR_ACTIVE,
  };
};

export const CollapseCX2SidebarInactiveAction = () => {
  return {
    type: COLLAPSE_CX2_SIDEBAR_INACTIVE,
  };
};
