import React, { Component } from "react";
import CSVSVGReceive from "../../../Assets/svg/CSVSVGRecieve.svg";
import CSVReceiveMethodPopUp from "./CSVRecieveMethodPopUp";
import CSVSentToMail from "./CSVSentToMail";
import CSVProcessingOnSite from "./CSVProcessingOnSite";
import CSVProcessingOnSiteFailed from "./CSVProcessingOnSiteFailed";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FetchIndividualFileAction } from "../../../Actions/Analysis";

class PopUp extends Component {
  static propTypes = {
    csvAnalysisSuccess: PropTypes.bool,
    SendToMail: PropTypes.func.isRequired,
    RenderOnSite: PropTypes.func.isRequired,
    isSentToMail: PropTypes.bool,
    isRenderOnSite: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
    csvAnalysisSuccessMessage: PropTypes.string,
    FetchIndividualFileAction: PropTypes.func.isRequired,
    AddedFileID: PropTypes.string,
    pdfAnalysisSuccess: PropTypes.bool,
  };

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.csvAnalysisSuccess &&
        this.props.csvAnalysisSuccess &&
        this.props.isRenderOnSite) ||
      (!prevProps.pdfAnalysisSuccess &&
        this.props.pdfAnalysisSuccess &&
        this.props.isRenderOnSite)
    ) {
      this.props.navigate(`result-summary/${this.props.AddedFileID}`);
      // this.props.FetchIndividualFileAction(this.props.AddedFileID);
    }
  }

  render() {
    return (
      <div className="fixed w-full h-screen inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
        {!this.props.csvFileUploadSuccess &&
          !(this.props.isSentToMail || this.props.isRenderOnSite) && (
            <CSVReceiveMethodPopUp />
          )}
        {this.props.isSentToMail && <CSVSentToMail />}
        {this.props.isRenderOnSite && !this.props.csvAnalysisSuccess && (
          <CSVProcessingOnSite />
        )}
        {this.props.isRenderOnSite &&
          !this.props.csvAnalysisSuccess &&
          this.props.csvAnalysisSuccessMessage && <CSVProcessingOnSiteFailed />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  csvAnalysisSuccess: state.analysis.csvAnalysisSuccess,
  isSentToMail: state.analysis.isSentToMail,
  isRenderOnSite: state.analysis.isRenderOnSite,
  csvAnalysisSuccessMessage: state.analysis.csvAnalysisSuccessMessage,
  AddedFileID: state.analysis.AddedFileID,
  pdfAnalysisSuccess: state.analysis.pdfAnalysisSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { FetchIndividualFileAction })(PopUp)
);
