import {
  FETCH_CHAT_SESSIONS_SUCCESS,
  FETCH_CHAT_SESSIONS_FAIL,
  FETCH_INDIVIDUAL_CHAT_SESSION_SUCCESS,
  FETCH_INDIVIDUAL_CHAT_SESSION_FAIL,
  CONTINUE_CHAT_SESSION_ADD_SESSION_ID_TO_REDUX,
  MESSAGE_SENT_TO_CHAT_BOT_SUCCESS,
  MESSAGE_SENT_TO_CHAT_BOT_FAIL,
  NEW_CHAT_SESSION_STARTED_SUCCESS,
  NEW_CHAT_SESSION_STARTED_FAIL,
  SET_HAS_RESPONDED,
  RESET_CONTINUE_CHAT_SESSION,
  CHAT_SESSION_IS_NEW_RESET,
  SESSION_DELETE_SUCCESS,
  SESSION_DELETE_FAIL,
  CLEAR_INDIVIDUAL_CHAT_SESSION,
  HAS_CLICKED_ON_SEND_ACTIVE,
  HAS_CLICKED_ON_SEND_INACTIVE,
  ADDED_AI_MODEL,
} from "../Actions/Types";

const initialState = {
  chatSessions: null,
  chatSessionsFetchSuccess: false,
  SideBarchatSessions: null,
  SideBarchatSessionsFetchSuccess: false,
  individualChatSession: null,
  individualChatSessionFetchSuccess: false,
  continueChatSessionId: null,
  isContinueChatSessionIdAddedToRedux: false,
  hasResponded: false,
  isNewChatSession: false,
  isClicked: false,
  individualChatSessionFailMessage: null,
  model: null,
  modelName: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CHAT_SESSIONS_SUCCESS:
      return {
        ...state,
        SideBarchatSessions: action.payload,
        SideBarchatSessionsFetchSuccess: true,
      };
    case FETCH_CHAT_SESSIONS_FAIL:
      return {
        ...state,
        chatSessions: null,
        chatSessionsFetchSuccess: false,
      };
    case FETCH_INDIVIDUAL_CHAT_SESSION_SUCCESS:
      return {
        ...state,
        individualChatSession: action.payload,
        individualChatSessionFetchSuccess: true,
      };
    case FETCH_INDIVIDUAL_CHAT_SESSION_FAIL:
      return {
        ...state,
        individualChatSession: null,
        individualChatSessionFetchSuccess: false,
        individualChatSessionFailMessage: action.payload.detail,
      };
    case CONTINUE_CHAT_SESSION_ADD_SESSION_ID_TO_REDUX:
      return {
        ...state,
        continueChatSessionId: action.payload,
        isContinueChatSessionIdAddedToRedux: true,
      };
    case RESET_CONTINUE_CHAT_SESSION:
      return {
        ...state,
        individualChatSession: [],
        continueChatSessionId: null,
        isContinueChatSessionIdAddedToRedux: false,
        isNewChatSession: true,
        individualChatSessionFailMessage: null,
      };
    case MESSAGE_SENT_TO_CHAT_BOT_SUCCESS:
      return {
        ...state,
        hasResponded: true,
      };
    case SET_HAS_RESPONDED:
      return {
        ...state,
        hasResponded: action.payload,
      };
    case NEW_CHAT_SESSION_STARTED_SUCCESS:
      return {
        ...state,
        isNewChatSession: true,
      };
    case NEW_CHAT_SESSION_STARTED_FAIL:
      return {
        ...state,
        isNewChatSession: false,
      };
    case CHAT_SESSION_IS_NEW_RESET:
      return {
        ...state,
        isNewChatSession: false,
      };
    case CLEAR_INDIVIDUAL_CHAT_SESSION:
      return {
        ...state,
        individualChatSession: null,
      };
    case HAS_CLICKED_ON_SEND_ACTIVE:
      return {
        ...state,
        isClicked: true,
      };
    case HAS_CLICKED_ON_SEND_INACTIVE:
      return {
        ...state,
        isClicked: false,
      };
    case ADDED_AI_MODEL:
      return {
        ...state,
        model: action.payload.model,
        modelName: action.payload.modelName,
      };
    default:
      return state;
  }
}
