import React, { Component } from "react";
import { Link } from "react-router-dom";
import CESSVG from "../../Assets/svg/CESSVG.svg";
import ChangePasswordImage from "../../Assets/jpeg/OverLay.jpg";
import ArrowLeftSVG from "../../Assets/svg/ArrowLeftSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import { ConfirmPasswordAction } from "../../Actions/Authentication";

class Confirmforgotpassword extends Component {
  state = {
    new_password: "",
    new_password_confirm: "",
  };

  static propTypes = {
    ConfirmPasswordAction: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    forgotPasswordEmail: PropTypes.string,
    ForgotPasswordOtp: PropTypes.string,
    isPasswordChangedSuccess: PropTypes.bool,
  };

  // Handle form submission
  onSubmit = (e) => {
    e.preventDefault();
    const { new_password, new_password_confirm } = this.state;

    if (new_password !== new_password_confirm) {
      alert("Passwords do not match.");
      return;
    }

    const { ForgotPasswordOtp, forgotPasswordEmail } = this.props;

    // Dispatch ConfirmPasswordAction with the OTP and email
    this.props.ConfirmPasswordAction(
      forgotPasswordEmail,
      ForgotPasswordOtp,
      new_password
    );
  };

  // Handle input change and update state
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.isPasswordChangedSuccess &&
      this.props.isPasswordChangedSuccess !== prevProps.isPasswordChangedSuccess
    ) {
      this.props.navigate("/login");
    }
  }

  render() {
    return (
      <div className="flex w-full h-screen justify-center items-center">
        <div className="flex flex-row">
          <div className="flex items-start pt-[1rem] justify-center w-[32.063rem] h-[55.188rem]">
            <div className="flex flex-col justify-center items-center w-[27.313rem] gap-[3rem]">
              <div className="flex flex-col gap-[6rem] w-full">
                <div className="w-[4.438rem] h-[1.925rem]">
                  <img src={CESSVG} alt="CESSVG" className="w-full h-full" />
                </div>
                <div className="flex flex-col">
                  <div className="font-SFPro font-[700] text-[2.5rem] relative right-0">
                    Set new password
                  </div>
                  <div className="font-SFPro font-[400] text-[1.25rem] text-[#443F3F]">
                    Must be at least 8 characters
                  </div>
                </div>
              </div>

              <form
                onSubmit={this.onSubmit}
                className="flex flex-col gap-[1.5rem] w-full"
              >
                <div>
                  <input
                    type="password"
                    name="new_password"
                    value={this.state.new_password}
                    onChange={this.onChange}
                    placeholder="Enter your password"
                    className="w-[27.313rem] h-[3.75rem] border-x-0 border-t-0 border-[1px] border-[#8E3A90] mt-[0.4rem] focus:border-b-[#8E3A90] focus:outline-none"
                    required
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="new_password_confirm"
                    value={this.state.new_password_confirm}
                    onChange={this.onChange}
                    placeholder="Confirm your password"
                    className="w-[27.313rem] h-[3.75rem] border-x-0 border-t-0 border-[1px] border-[#8E3A90] mt-[0.4rem] focus:border-b-[#8E3A90] focus:outline-none"
                    required
                  />
                </div>
                <div className="w-full">
                  <button
                    type="submit"
                    className="w-full h-[3.75rem] bg-[#7EC14D] text-white text-[600] font-inter text-[1.5rem]"
                  >
                    Reset Password
                  </button>
                </div>
              </form>

              <Link to={"/login"} className="flex gap-[1rem] items-center mt-4">
                <div className="w-[2rem]">
                  <img src={ArrowLeftSVG} alt="arrow left svg" />
                </div>
                <div className="text-[#443F3F] text-[1.125rem] font-inter font-[400]">
                  Back to login in
                </div>
              </Link>
            </div>
          </div>
          <div>
            <img
              src={ChangePasswordImage}
              alt="SignUpPageImage"
              className="w-[45.625rem] h-[55.188rem]"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forgotPasswordEmail: state.authentication.forgotPasswordEmail,
  ForgotPasswordOtp: state.authentication.ForgotPasswordOtp,
  isPasswordChangedSuccess: state.authentication.isPasswordChangedSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, { ConfirmPasswordAction })(Confirmforgotpassword)
);
