import {
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAIL,
  BAN_USER_SUCCESS,
  BAN_USER_FAIL,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  SERVICE_ACCESS_GRANTED_SUCCESS,
  SERVICE_ACCESS_GRANTED_FAIL,
} from "../Actions/Types";

const initialState = {
  allUsersData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  allUsersError: null,
  allUsersLoading: true,
  loadedAllUsersSuccess: null,
  loadedAllUsersFail: null,
  banDetails: null,
  fetchUserSuccess: null,
  loadingUser: null,
  firstName: null,
  lastName: null,
  email: null,
  isAdmin: null,
  isSuperuser: null,
  isSubscribed: null,
  hasAccessToAnalytics: null,
  hasAccessToAI: null,
  hasAccessToMessaging: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsersData: action.payload,
        allUsersLoading: false,
        loadedAllUsersSuccess: true,
        loadedAllUsersFail: false,
      };
    case FETCH_ALL_USERS_FAIL:
      return {
        ...state,
        allUsersData: {
          count: 0,
          next: null,
          previous: null,
          results: [],
        },
        allUsersError: "Failed to fetch all users",
        allUsersLoading: false,
        loadedAllUsersSuccess: false,
        loadedAllUsersFail: true,
      };
    case BAN_USER_SUCCESS:
      return {
        ...state,
        banDetails: action.payload, // Store the ban/unban details
        allUsersLoading: false,
        loadedAllUsersSuccess: true,
        loadedAllUsersFail: false,
      };
    case BAN_USER_FAIL:
      return {
        ...state,
        allUsersError: "Failed to ban/unban user",
        allUsersLoading: false,
        loadedAllUsersSuccess: false,
        loadedAllUsersFail: true,
        banDetails: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        fetchUserSuccess: true,
        loadingUser: false,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        email: action.payload.email,
        lastLogin: action.payload.last_login,
        isBanned: action.payload.is_banned,
        isAdmin: action.payload.is_staff,
        isSuperuser: action.payload.is_superuser,
        isSubscribed: action.payload.is_subscribed,
        hasAccessToSmartIQ: action.payload.has_access_to_smart_iq,
        hasAccessToSmartExtract: action.payload.has_access_to_smart_extract,
        hasAccessToAI: action.payload.has_access_to_ai,
        hasAccessToMessaging: action.payload.has_access_to_messaging,
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        fetchUserSuccess: false,
        loadingUser: false,
        firstName: null,
        lastName: null,
        email: null,
        isAdmin: null,
        isSuperuser: null,
        isSubscribed: null,
        hasAccessToAnalytics: null,
        hasAccessToAI: null,
        hasAccessToMessaging: null,
      };
    case SERVICE_ACCESS_GRANTED_SUCCESS:
      return {
        ...state,
        grantAccessEmail: action.payload.email,
        grantAccessIsSubscribed: action.payload.is_subscribed,
        grantAccessHasSmartIQ: action.payload.has_access_to_smart_iq,
        grantAccessHasSmartExtract: action.payload.has_access_to_smart_extract,
        grantAccessHasAI: action.payload.has_access_to_ai,
        grantAccessHasMessaging: action.payload.has_access_to_messaging,
      };
    default:
      return state;
  }
}
