import React, { Component } from "react";
import MagnifyingGlassSVG from "../../../Assets/svg/MagnifyingGlassSVG.svg";
import DotsThreeSVG from "../../../Assets/svg/DotsThreeSVG.svg";
import EmailRPlaceHolder from "../../../Assets/svg/EmailRPlaceHolder.svg";
import EmailSPlaceHolder from "../../../Assets/svg/EmailSPlaceHolder.svg";
import EmailDPlaceHolder from "../../../Assets/svg/EmailDPlaceHolder.svg";

class EmailChatList extends Component {
  render() {
    return (
      <div className="flex flex-col w-[30%] h-full gap-[1rem] px-[1rem] pt-[2rem] border-[1px] border-[#D9D9D9] rounded-[9px]">
        <div className="flex justify-between">
          <div className="font-[700] text-[1.5rem] font-SFPro">All Inboxes</div>
          <img src={DotsThreeSVG} alt="Dots Three" />
        </div>

        <div className="bg-[#D9D9D9] w-full h-[1px]"></div>
        <div className="flex w-full h-[3.625rem] justify-between gap-[1rem] relative">
          <img
            className="absolute top-[25%] left-[1rem] z-20 cursor-pointer size-[1.688rem]"
            src={MagnifyingGlassSVG}
            alt="Search Icon"
          />
          <input
            type="text"
            placeholder="Search"
            className="w-full py-4 pl-[4rem] font-SFPro text-[1.5rem] bg-[#F0F2F5] font-[400] border border-[#DDD9D9] focus:border focus:border-[#A12F9F] outline-none rounded-[7px] shadow-sm"
          />
        </div>
        <div className="p-[1rem] flex flex-col gap-[1rem]">
          <div className="flex gap-[1rem] border-b-[1px] border-[#D9D9D9] pb-[1rem]">
            <img src={EmailRPlaceHolder} alt="Search Icon" />
            <div className="w-full flex justify-between items-center">
              <div>
                <div className="font-[700] font-SFPro text-[1.5rem]">
                  Racheal Thomas
                </div>
                <div className="font-[500] font-inter text-[0.875rem] text-[#5D5D5D]">
                  How are you doing?
                </div>
              </div>
              <div className="flex flex-col gap-[0.5rem]">
                <div className="font-[400] font-SFPro text-[0.875rem]">
                  10:00pm
                </div>
                <div className="size-[1.25rem] rounded-[50%] bg-[#8E3B8D] text-center text-white">
                  1
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-[1rem] border-b-[1px] border-[#D9D9D9] pb-[1rem]">
            <img src={EmailSPlaceHolder} alt="Search Icon" />
            <div className="w-full flex justify-between items-center">
              <div>
                <div className="font-[700] font-SFPro text-[1.5rem]">
                  Sabastian Ego
                </div>
                <div className="font-[500] font-inter text-[0.875rem] text-[#5D5D5D]">
                  How are you doing?
                </div>
              </div>
              <div className="flex flex-col gap-[0.5rem]">
                <div className="font-[400] font-SFPro text-[0.875rem]">
                  20:00pm
                </div>
                <div className="size-[1.25rem] rounded-[50%] bg-[#8E3B8D] text-center text-white">
                  1
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-[1rem] border-b-[1px] border-[#D9D9D9] pb-[1rem]">
            <img src={EmailDPlaceHolder} alt="Search Icon" />
            <div className="w-full flex justify-between items-center">
              <div>
                <div className="font-[700] font-SFPro text-[1.5rem]">
                  Diego Sam
                </div>
                <div className="font-[500] font-inter text-[0.875rem] text-[#5D5D5D]">
                  is it possible i send my document in?
                </div>
              </div>
              <div className="flex flex-col gap-[0.5rem]">
                <div className="font-[400] font-SFPro text-[0.875rem]">
                  12:00pm
                </div>
                <div className="size-[1.25rem] rounded-[50%] bg-[#8E3B8D] text-center text-white">
                  1
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailChatList;
