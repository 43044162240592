// AIChatBot.js

import React, { Component } from "react";
import NewChat from "./NewChat";
import BackbuttonSVG from "../../../Assets/svg/BackbuttonSVG.svg";
import ChatStarted from "./ChatStarted";
import AIInput from "./AIInput"; // Import AIInput
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import { connect } from "react-redux";

class AIChatBot extends Component {
  static propTypes = {
    continueChatSessionId: PropTypes.number, // Changed to number
    isContinueChatSessionIdAddedToRedux: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
  };

  handleChatStarted = () => {
    if (this.props.location.pathname.includes("/dashboard/ai-chatbot/")) {
      return <ChatStarted />;
    } else if (this.props.location.pathname === "/dashboard/ai-chatbot") {
      return <NewChat />;
    }
  };

  handleBackToHome = () => {
    this.props.navigate("..");
  };

  render() {
    return (
      <div className="flex justify-center items-center w-full h-screen bg-[#F7F8FA] p-[0.8rem] overflow-hidden edge-effect relative">
        <button onClick={this.handleBackToHome} className="z-50">
          <img
            src={BackbuttonSVG}
            alt="back button"
            className="absolute top-[2.5rem] right-[5rem] w-[2rem] h-[2rem]"
          />
        </button>
        <div className="w-full h-full bg-[#FFFFFF] rounded-[0.75rem] overflow-y-auto flex flex-col items-center">
          <div className="flex-grow w-full flex justify-center items-center">
            {this.handleChatStarted()}
          </div>
        </div>
        {/* AIInput fixed at the bottom */}
        <div className="fixed bottom-0 w-full bg-white flex justify-center py-[1rem]">
          <div className="w-full max-w-[67.5rem] px-[1rem]">
            <AIInput />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  continueChatSessionId: state.ai.continueChatSessionId,
  isContinueChatSessionIdAddedToRedux:
    state.ai.isContinueChatSessionIdAddedToRedux,
});

export default withRouterHooks(connect(mapStateToProps)(AIChatBot));
