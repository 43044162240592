import React, { Component } from "react";
import { ResetAnalysisReduxStateAction } from "../../../Actions/Analysis";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";

class UploadingCSVFile extends Component {
  static propTypes = {
    ResetAnalysisReduxStateAction: PropTypes.func.isRequired,
  };

  handleCancelUpload = () => {
    this.props.ResetAnalysisReduxStateAction();
    window.location.reload();
  };

  render() {
    return (
      <div className="flex flex-col w-full h-[18.313rem] items-center justify-center font-inter text-[1.5rem] leading-[1.816rem] gap-[2rem] border-[1px] border-[#CC90CB] border-dashed">
        <div className="relative flex items-center justify-center w-[6.125rem] h-[6.125rem]">
          <div className="absolute w-full h-full border-4 border-t-transparent border-b-transparent border-solid border-[#D20C9B] rounded-full animate-spin"></div>
          <div className="flex items-center justify-center bg-white w-[5.125rem] h-[5.125rem] rounded-full z-10">
            60%
          </div>
        </div>
        <div className="flex flex-col gap-[1rem] items-center">
          <div>Uploading File...</div>
          <div>
            <button
              onClick={this.handleCancelUpload}
              className="text-[1.25rem] w-[10.375rem] h-fit p-[0.1rem] px-[0.5rem] py-[0.6rem] border-[1px] border-[#DDD9D9] bg-[#F9F9F9]"
            >
              Cancel Upload
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouterHooks(
  connect(mapStateToProps, { ResetAnalysisReduxStateAction })(UploadingCSVFile)
);
