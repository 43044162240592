import React, { Component } from "react";
import { Link } from "react-router-dom";
import CESSVG from "../../Assets/svg/CESSVG.svg";
import LoginImage from "../../Assets/jpeg/LoginOverLay.jpg";
import {
  LoginAction,
  FlushAuthenticationAction,
} from "../../Actions/Authentication";
import { ResetAnalysisReduxStateAction } from "../../Actions/Analysis";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import Spinner from "../Animations/Spinner/Spinner";
import AuthenticationMessageDispatcher from "./AuthenticationMessageDispatcher";

class Login extends Component {
  state = {
    email: "",
    password: "",
    loggingIn: false,
    showErrorMessage: false,
  };

  static propTypes = {
    LoginAction: PropTypes.func.isRequired,
    ResetAnalysisReduxStateAction: PropTypes.func.isRequired,
    FlushAuthenticationAction: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
    authenticationNotSuccessful: PropTypes.bool,
  };

  timeoutId = null;

  componentDidMount() {
    this.props.FlushAuthenticationAction();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevProps.isAuthenticated
    ) {
      this.setState({ loggingIn: true });
      this.timeoutId = setTimeout(() => {
        if (this.handlePath().path === "/login") {
          this.props.navigate("/dashboard");
        } else if (this.handlePath().path === "/admin-login") {
          this.props.navigate("/admin-dashboard");
        }
      }, 3000);
    }
    if (
      this.props.authenticationNotSuccessful &&
      this.props.authenticationNotSuccessful !==
        prevProps.authenticationNotSuccessful
    ) {
      this.setState({ showErrorMessage: true });
      this.timeoutId = setTimeout(() => {
        this.setState({ showErrorMessage: false });
        this.props.FlushAuthenticationAction();
      }, 2000);
    }
  }

  componentWillUnmount() {
    // Clear the timeout to prevent memory leaks
    clearTimeout(this.timeoutId);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.LoginAction(this.state.email, this.state.password);
    this.props.ResetAnalysisReduxStateAction();
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handlePath = () => {
    let pathVar = {
      text: "",
      path: "",
    };
    if (this.props.location.pathname === "/login") {
      pathVar.text = "Login";
      pathVar.path = "/login";
    } else if (this.props.location.pathname === "/admin-login") {
      pathVar.text = "Admin Login";
      pathVar.path = "/admin-login";
    }

    return pathVar;
  };

  render() {
    const { email, password } = this.state;

    return (
      <div className="flex w-full h-screen justify-center items-center">
        <div className="flex flex-col items-center">
          <div className="flex ">
            <div className="flex items-center justify-center w-[32.063rem] h-[55.188rem]">
              <div className="flex flex-col items-center gap-[2rem]">
                {this.state.showErrorMessage && (
                  <AuthenticationMessageDispatcher />
                )}
                <div className="flex flex-col justify-center items-center w-[27.313rem] gap-[1rem]">
                  <div className="w-[4.438rem] h-[1.925rem]">
                    <img src={CESSVG} alt="CESSVG" className="w-full h-full" />
                  </div>
                  <div className="font-SFPro font-[700] text-[2.5rem]">
                    {this.handlePath().text}
                  </div>
                  <div className="text-center font-SFPro font-[400] text-[1.25rem] text-[#443F3F]">
                    Welcome back! please enter your details
                  </div>
                  <form
                    onSubmit={this.onSubmit}
                    className="flex flex-col gap-[1rem]"
                  >
                    <div>
                      <div className="font-inter font-[600]">Email</div>
                      <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={this.onChange}
                        placeholder="Enter your email"
                        className="w-[27.313rem] h-[3.75rem] border-[1px] border-[#8E3A90] rounded-[0.625rem] mt-[0.4rem] pl-[1.25rem] focus:outline-[#8E3A90]"
                        autoComplete="on"
                      />
                    </div>
                    <div>
                      <div className="font-inter font-[600]">Password</div>
                      <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={this.onChange}
                        placeholder="Enter password"
                        className="w-[27.313rem] h-[3.75rem] border-[1px] border-[#8E3A90] rounded-[0.625rem] mt-[0.4rem] pl-[1.25rem] focus:outline-[#8E3A90]"
                        autoComplete="on"
                      />
                    </div>
                    <div className="w-full">
                      <button
                        type="submit"
                        className="flex gap-[1rem] justify-center items-center w-full h-[3.75rem] rounded-[2.438rem] bg-[#8E3B8D] text-white text-[600] font-inter text-[1.5rem]"
                      >
                        <div>Login</div>
                        {this.state.loggingIn && <Spinner />}
                      </button>
                    </div>
                  </form>
                  {this.handlePath().path === "/login" ? (
                    <>
                      <div>
                        Don't have an account?{" "}
                        <Link to={"/signup"} className="text-[#8E3A90]">
                          Signup
                        </Link>
                      </div>
                      <div>
                        <Link to={"/forgotpassword"} className="text-[#8E3A90]">
                          Forgot Password?
                        </Link>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="hidden sm:block">
              <img
                src={LoginImage}
                alt="SignUpPageImage"
                className="w-[45.625rem] h-[55.188rem] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication?.isAuthenticated,
  authenticationNotSuccessful:
    state.authentication?.authenticationNotSuccessful,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    LoginAction,
    FlushAuthenticationAction,
    ResetAnalysisReduxStateAction,
  })(Login)
);
