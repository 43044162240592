import React from "react";
import { ReactTyped as Typed } from "react-typed";
import SmartflowIllustrationSVG from "../../../Assets/svg/SmartflowIllustration.svg";

const SmartFlowWelcomeDialogue = () => {
  return (
    <div className="w-[90%] h-[28.938rem] flex flex-col justify-center items-center bg-[#FFFFFF] rounded-[1rem] gap-[1rem]">
      <img
        className="w-[17.25rem] h-[11.313rem]"
        src={SmartflowIllustrationSVG}
        alt="SmartflowIllustrationSVG"
      />
      <div className="font-[500] font-inter text-[2rem] w-[51.75rem] h-[5.875rem] text-center">
        <Typed
          strings={[
            "Welcome to SmartFlow auto-form filler assistant. I will be assisting you in filling out your form.",
          ]}
          typeSpeed={15}
        />
        <div className="font-inter font-[500] text-[1.75rem] text-[#3366CC]">
          Click anywhere to Skip
        </div>
      </div>
    </div>
  );
};

export default SmartFlowWelcomeDialogue;
