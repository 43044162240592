import {
  CSV_FILE_UPLOAD_SUCCESS,
  CSV_FILE_UPLOAD_FAIL,
  FILE_UPLOAD_TRIGGERED_SUCCESS,
  FILE_UPLOAD_TRIGGERED_FAILED,
  RESET_ANALYSIS_REDUX_STATE,
  FETCH_CSV_SUCCESS,
  FETCH_CSV_FAIL,
  DELETE_CSV_SUCCESS,
  DELETE_CSV_FAIL,
  CSV_ANALYSIS_SUCCESS,
  CSV_ANALYSIS_FAIL,
  SENT_TO_MAIL,
  RENDER_ON_SITE,
  ADD_ANALYSIS_FILE_NAMES_TO_REDUX,
  RESET_ANALYSIS_MESSAGE_REDUX_STATE,
  FETCH_INDIVIDUAL_FILE_SUCCESS,
  FETCH_INDIVIDUAL_FILE_FAIL,
  RENDER_PDF_FILE_SUCCESS,
  RENDER_PDF_FILE_FAIL,
  ADD_ANALYSIS_FILE_ID_TO_REDUX,
} from "../Actions/Types";

const initialState = {
  csvFileUploadSuccess: false,
  csvFileUploadFail: false,
  isFileUploading: false,
  csvData: null,
  isDeleted: false,
  csvAnalysisSuccess: false,
  csvAnalysisSuccessMessage: null,
  csvAnalysisSuccessReport: null,
  isSentToMail: false,
  isRenderOnSite: false,
  analysisFileName: null,
  pdfAnalysisMessage: null,
  pdfAnalysisOutput: null,
  pdfAnalysisSuccess: false,
  analysisOutput: null,
  isIndividualFileFetched: false,
  renderedPDF: null,
  PDFisRendered: false,
  AddedFileID: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CSV_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        csvFileUploadSuccess: true,
      };
    case CSV_FILE_UPLOAD_FAIL:
      return {
        ...state,
        csvFileUploadFail: true,
      };
    case FILE_UPLOAD_TRIGGERED_SUCCESS:
      return {
        ...state,
        isFileUploading: true,
      };
    case FILE_UPLOAD_TRIGGERED_FAILED:
      return {
        ...state,
        isFileUploading: false,
      };
    case RESET_ANALYSIS_REDUX_STATE:
      return {
        ...state,
        csvFileUploadSuccess: false,
        pdfAnalysisSuccess: false,
        csvFileUploadFail: false,
        isFileUploading: false,
        csvAnalysisSuccess: false,
        isSentToMail: false,
        isRenderOnSite: false,
        isIndividualFileFetched: false,
      };
    case FETCH_CSV_SUCCESS:
      return {
        ...state,
        csvData: action.payload.files,
      };
    case FETCH_CSV_FAIL:
      return {
        ...state,
        csvData: null,
      };
    case DELETE_CSV_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };
    case DELETE_CSV_FAIL:
      return {
        ...state,
        isDeleted: false,
      };
    case CSV_ANALYSIS_SUCCESS:
      return {
        ...state,
        ...(action.payload.message ===
          "PDF has been extracted successfully." && {
          pdfAnalysisSuccess: true,
          pdfAnalysisMessage: action.payload.message,
          pdfAnalysisOutput: action.payload.data.data,
        }),
        ...(action.payload.message ===
          "CSV Analysis has been completed and sent to your email." && {
          csvAnalysisSuccess: true,
          csvAnalysisSuccessMessage: action.payload.message,
          csvAnalysisSuccessReport: action.payload.report,
        }),
      };
    case CSV_ANALYSIS_FAIL:
      return {
        ...state,
        csvAnalysisSuccess: false,
        csvAnalysisSuccessMessage: null,
        csvAnalysisSuccessReport: null,
      };
    case SENT_TO_MAIL:
      return {
        ...state,
        isSentToMail: true,
      };
    case RENDER_ON_SITE:
      return {
        ...state,
        isRenderOnSite: true,
      };
    case ADD_ANALYSIS_FILE_NAMES_TO_REDUX:
      return {
        ...state,
        analysisFileName: action.payload,
      };
    case RESET_ANALYSIS_MESSAGE_REDUX_STATE:
      return {
        ...state,
        csvAnalysisSuccessMessage: null,
      };
    case FETCH_INDIVIDUAL_FILE_SUCCESS:
      return {
        ...state,
        ...(action.payload.file_type === "CSV" && {
          analysisFileName: action.payload.file_name,
          isIndividualFileFetched: true,
          csvAnalysisSuccessReport: action.payload.analysis_output,
          csvAnalysisSuccessMessage: "Analysis Successful",
          // csvAnalysisSuccess: true,
        }),
        ...(action.payload.file_type === "PDF" && {
          analysisFileName: action.payload.file_name,
          isIndividualFileFetched: true,
          pdfAnalysisMessage: action.payload.message,
          pdfAnalysisOutput: action.payload.analysis_output.data,
          // pdfAnalysisSuccess: true,
          pdfAnalysisMessage: "Analysis Successful",
        }),
      };
    case FETCH_INDIVIDUAL_FILE_FAIL:
      return {
        ...state,
        isIndividualFileFetched: false,
      };
    case RENDER_PDF_FILE_SUCCESS:
      return {
        ...state,
        renderedPDF: action.payload.url,
        contentType: action.payload.contentType, // Content type
        PDFisRendered: true,
      };
    case ADD_ANALYSIS_FILE_ID_TO_REDUX:
      return {
        ...state,
        AddedFileID: action.payload,
      };
    // // case FAKE_CSV_ANALYSIS_SUCCESS:
    //   return {
    //     ...state,
    //   };
    default:
      return state;
  }
}
