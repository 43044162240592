import {
  COLLAPSE_CX2_SIDEBAR_ACTIVE,
  COLLAPSE_CX2_SIDEBAR_INACTIVE,
  PDF_ANALYSIS_COMPLETE_POP_UP,
  PDF_ANALYSIS_COMPLETE_POP_UP_RESET,
} from "../Actions/Types";

const initialState = {
  isCollapsed: true,
  //   pdfAnalysisIsComplete: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case COLLAPSE_CX2_SIDEBAR_ACTIVE:
      return {
        ...state,
        isCollapsed: true,
      };
    case COLLAPSE_CX2_SIDEBAR_INACTIVE:
      return {
        ...state,
        isCollapsed: false,
      };
    case PDF_ANALYSIS_COMPLETE_POP_UP:
    //   return {
    //     ...state,
    //     pdfAnalysisIsComplete: true,
    //   };
    // case PDF_ANALYSIS_COMPLETE_POP_UP_RESET:
    //   return {
    //     ...state,
    //     pdfAnalysisIsComplete: false,
    //   };
    default:
      return state;
  }
}
