import {
  FETCH_FORM_TYPES_SUCCESS,
  FETCH_FORM_TYPES_FAIL,
  CREATE_FORM_INSTANCE_SUCCESS,
  CREATE_FORM_INSTANCE_FAIL,
  FETCH_FORM_STRUCTURE_SUCCESS,
  FETCH_FORM_STRUCTURE_FAIL,
  ADD_FORM_INSTANCE_ID_TO_REDUX,
  FORM_ENTRY_ADDED_SUCCESS,
  FORM_ENTRY_ADDED_FAIL,
  RESET_FORM_REDUX_STATE,
  FORM_ENTRY_RENDERED_SUCCESS,
  FORM_ENTRY_RENDERED_FAIL,
} from "../Actions/Types";

const initialState = {
  formTypes: null,
  formInstance: null,
  formInstanceID: null,
  formStructure: null,
  formEntryAdded: false,
  renderFilledForm: null,
  formInstanceName: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FORM_TYPES_SUCCESS:
      return {
        ...state,
        formTypes: action.payload,
      };
    case FETCH_FORM_TYPES_FAIL:
      return {
        ...state,
        formTypes: null,
      };
    case CREATE_FORM_INSTANCE_SUCCESS:
      return {
        ...state,
        formInstance: action.payload,
      };
    case CREATE_FORM_INSTANCE_FAIL:
      return {
        ...state,
        formInstance: null,
      };
    case FETCH_FORM_STRUCTURE_SUCCESS:
      return {
        ...state,
        formStructure: action.payload,
      };
    case FETCH_FORM_STRUCTURE_FAIL:
      return {
        ...state,
        formStructure: null,
      };
    case ADD_FORM_INSTANCE_ID_TO_REDUX:
      return {
        ...state,
        formInstanceID: action.payload.id,
        formInstanceName: action.payload.name,
      };
    case FORM_ENTRY_ADDED_SUCCESS:
      return {
        ...state,
        formEntryAdded: true,
      };
    case FORM_ENTRY_ADDED_FAIL:
      return {
        ...state,
        formEntryAdded: false,
      };
    case RESET_FORM_REDUX_STATE:
      return {
        ...state,
        formInstance: null,
        formInstanceID: null,
        formStructure: null,
        formEntryAdded: false,
      };
    case FORM_ENTRY_RENDERED_SUCCESS:
      return {
        ...state,
        renderFilledForm: action.payload,
      };
    case FORM_ENTRY_RENDERED_FAIL:
      return {
        ...state,
        renderFilledForm: null,
      };
    default:
      return state;
  }
}
