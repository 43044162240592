import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  ACCOUNT_VERIFICATION_SUCCESS,
  ACCOUNT_VERIFICATION_FAIL,
  FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_SUCCESS,
  PASSWORD_CHANGED_SUCCESS,
  PASSWORD_CHANGED_FAIL,
  FLUSH_ALL_AUTHENTICATION_REDUX_STATE,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
} from "../Actions/Types";

const initialState = {
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: false,
  isSignupSuccess: false,
  user: null,
  email: null,
  isVerified: false,
  isForgotPasswordSuccess: null,
  ForgotPasswordOtp: null,
  isPasswordChangedSuccess: false,
  userFetchSuccess: false,
  user: null,
  message: null,
  authenticationNotSuccessful: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("access", action.payload.access);
      localStorage.setItem("refresh", action.payload.refresh);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        authenticationNotSuccessful: false,
        message: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        authenticationNotSuccessful: true,
        message: action.payload,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isSignupSuccess: true,
        email: action.payload.user.email,
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        isSignupSuccess: false,
      };
    case ACCOUNT_VERIFICATION_SUCCESS:
      return {
        ...state,
        isVerified: true,
      };
    case ACCOUNT_VERIFICATION_FAIL:
      return {
        ...state,
        isVerified: false,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPasswordSuccess: action.payload.message,
        forgotPasswordEmail: action.payload.email,
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isForgotPasswordSuccess: false,
      };
    case FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_SUCCESS:
      return {
        ...state,
        ForgotPasswordOtp: action.payload,
      };
    case PASSWORD_CHANGED_SUCCESS:
      return {
        ...state,
        isPasswordChangedSuccess: true,
      };
    case PASSWORD_CHANGED_FAIL:
      return {
        ...state,
        isPasswordChangedSuccess: false,
      };
    case FLUSH_ALL_AUTHENTICATION_REDUX_STATE:
      return {
        ...state,
        isSignupSuccess: false,
        isVerified: false,
        isForgotPasswordSuccess: null,
        ForgotPasswordOtp: null,
        isPasswordChangedSuccess: false,
        isPasswordChangedSuccess: false,
        ForgotPasswordOtp: null,
        email: null,
        isAuthenticated: false,
        isAuthenticated: false,
        authenticationSuccess: false,
        authenticationNotSuccessful: null,
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        userFetchSuccess: true,
        user: action.payload,
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        userFetchSuccess: false,
      };
    default:
      return state;
  }
}
