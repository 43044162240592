import React, { Component, createRef } from "react";
import CaretDownSVG from "../../../../Assets/svg/CaretDownSVG.svg";
import CheckMarkSVG from "../../../../Assets/svg/CheckMarkSVG.svg";
import PropTypes from "prop-types";
import { AddAIModelToRedux } from "../../../../Actions/AI";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";

class AIModelsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeModel: null,
    };
    this.dropdownRef = createRef();
  }

  static propTypes = {
    AddAIModelToRedux: PropTypes.func.isRequired,
    model: PropTypes.string,
    modelName: PropTypes.string,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ activeModel: null });
    }
  };

  handleChangeModel = (index) => {
    this.setState((prevState) => ({
      activeModel: prevState.activeModel === index ? null : index,
    }));
  };

  handleDocumentEnterprise = () => {
    this.props.AddAIModelToRedux("genii-d", "Enterprise Document");
  };

  handleDocument;

  render() {
    const { activeModel } = this.state;

    return (
      <div
        className="absolute top-[2.5rem] left-[4rem] w-fit h-fit flex flex-col items-center justify-center gap-[2rem] z-50 bg-white"
        ref={this.dropdownRef}
      >
        <div className="flex w-full gap-[1rem] items-center">
          <button
            className="w-[15.313rem] py-[0.8rem] flex gap-[1rem] justify-center bg-[#F7F8FA] rounded-[9px]"
            onClick={() => this.handleChangeModel(0)}
          >
            <div className="font-inter font-[500] text-[1rem]">
              {this.props.modelName || "Select AI Model"}
            </div>
            <img src={CaretDownSVG} className="size-[1.5rem]" alt="AI Model" />
          </button>
        </div>
        {activeModel === 0 && (
          <div className="w-[24.05rem] h-[8.438rem] border border-[#EEEDED] py-[1rem] rounded-[8px] shadow-custom-2 px-[1rem] flex flex-col gap-[1rem]">
            <button
              onClick={() => this.handleDocumentEnterprise()}
              className="flex flex-col gap-[0.2rem]"
            >
              <div className="flex w-full justify-between">
                <div className="font-inter font-[500] text-[1rem]">
                  Enterprise Document
                </div>
                {this.props.model === "genii-d" && (
                  <img src={CheckMarkSVG} alt="AI Model" />
                )}
              </div>
              <div className="font-inter font-[300] text-[0.75rem] text-[#000000]">
                For enterprise documentation
              </div>
            </button>
            <button className="flex flex-col gap-[0.2rem]">
              <div className="flex w-full justify-between">
                <div className="font-inter font-[500] text-[1rem]">
                  Financial Model
                </div>
                {this.props.model === "cx2" && (
                  <img src={CheckMarkSVG} alt="AI Model" />
                )}
              </div>
              <div className="font-inter font-[300] text-[0.75rem] text-[#000000]">
                For Financial modeling
              </div>
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  model: state.ai.model,
  modelName: state.ai.modelName,
});

export default withRouterHooks(
  connect(mapStateToProps, { AddAIModelToRedux })(AIModelsList)
);
