import axios from "./Axios";
import {
  FETCH_FORM_TYPES_SUCCESS,
  FETCH_FORM_TYPES_FAIL,
  CREATE_FORM_INSTANCE_SUCCESS,
  CREATE_FORM_INSTANCE_FAIL,
  FETCH_FORM_STRUCTURE_SUCCESS,
  FETCH_FORM_STRUCTURE_FAIL,
  ADD_FORM_INSTANCE_ID_TO_REDUX,
  FORM_ENTRY_ADDED_SUCCESS,
  FORM_ENTRY_ADDED_FAIL,
  RESET_FORM_REDUX_STATE,
  FORM_ENTRY_RENDERED_SUCCESS,
  FORM_ENTRY_RENDERED_FAIL,
} from "./Types";
import { returnErrors } from "./Errors";

export const FetchFormTypesAction = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/form/forms/available/");

    dispatch({
      type: FETCH_FORM_TYPES_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));

    dispatch({
      type: FETCH_FORM_TYPES_FAIL,
    });

    throw err;
  }
};

export const CreateFormInstanceAction = (formType) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/form/forms/${formType}/`);

    dispatch({
      type: CREATE_FORM_INSTANCE_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: CREATE_FORM_INSTANCE_FAIL,
    });
    throw err;
  }
};

export const FetchFormStructureAction = (formInstance) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/form/form-instances/${formInstance}/fetch-form-structure/`
    );
    dispatch({
      type: FETCH_FORM_STRUCTURE_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: FETCH_FORM_STRUCTURE_FAIL,
    });
    throw err;
  }
};

export const AddFormInstanceIDToReduxAction = (id, name) => {
  return {
    type: ADD_FORM_INSTANCE_ID_TO_REDUX,
    payload: { id, name },
  };
};

export const UpdateFormInstanceAction =
  (formInstance, data) => async (dispatch) => {
    try {
      const res = await axios.put(
        `/api/form/form-instances/${formInstance}/update/`,
        data
      );
      dispatch({
        type: FORM_ENTRY_ADDED_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: FORM_ENTRY_ADDED_FAIL,
      });
      throw err;
    }
  };

export const ResetFormReduxStateAction = () => {
  return {
    type: RESET_FORM_REDUX_STATE,
  };
};

export const RenderForm = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/form/form-instances/${id}/render/`);

    dispatch({
      type: FORM_ENTRY_RENDERED_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));

    dispatch({
      type: FORM_ENTRY_RENDERED_FAIL,
    });
    throw err;
  }
};

export const SaveFormInstanceAsPDF = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/form/form-instances/${id}/render/pdf/`);
    dispatch({
      type: FORM_ENTRY_RENDERED_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response?.data, err.response?.status));
    dispatch({
      type: FORM_ENTRY_RENDERED_FAIL,
    });
    throw err;
  }
};
