import axios from "./Axios";
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAILED,
  ACCOUNT_VERIFICATION_SUCCESS,
  ACCOUNT_VERIFICATION_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_SUCCESS,
  FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_FAIL,
  PASSWORD_CHANGED_SUCCESS,
  PASSWORD_CHANGED_FAIL,
  FLUSH_ALL_AUTHENTICATION_REDUX_STATE,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
} from "./Types";
import { returnErrors } from "./Errors";
import { FLUSH } from "redux-persist";

// SIGNUP USER
export const SignupAction =
  (
    first_name,
    last_name,
    middle_name,
    company_name,
    email,
    password,
    confirm_password
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      first_name,
      last_name,
      middle_name,
      company_name,
      email,
      password,
      confirm_password,
    });

    axios
      .post(`/api/userauth/create-user/`, body, config)
      .then((res) => {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
          type: SIGNUP_FAIL,
        });
      });
  };

// Verify Email
export const VerifyEmailAction = (email, otp) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, otp });

  return axios
    .post(`/api/userauth/verify-user-email/`, body, config)
    .then((res) => {
      dispatch({
        type: ACCOUNT_VERIFICATION_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: ACCOUNT_VERIFICATION_FAIL,
      });
    });
};

// Login User
export const LoginAction = (email, password) => (dispatch) => {
  // Set headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Convert data to JSON
  const body = JSON.stringify({ email, password });

  // Make the login request
  return axios
    .post(`/api/userauth/login/`, body, config)
    .then((res) => {
      // Dispatch login success with response data
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      // Optionally, store tokens in local storage
      if (res.data.access) {
        localStorage.setItem("access", res.data.access);
        localStorage.setItem("refresh", res.data.refresh);
      }

      // Return response data if needed for further actions
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        // Server responded with an error (e.g., 400 or 500)
        dispatch(returnErrors(err.response.data, err.response.status));
      } else if (err.request) {
        // No response was received (e.g., server timeout or network issue)
        dispatch(returnErrors("No response received from server", 500));
      } else {
        // Something happened in setting up the request
        dispatch(returnErrors("Error in setting up the request", 500));
      }

      // Dispatch login failure
      dispatch({
        type: LOGIN_FAIL,
        payload: "Login failed Invalid credentials",
      });
    });
};

// Forgot Password
export const ForgotPasswordAction = (email) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  return axios
    .post(`/api/userauth/reset-password/`, body, config)
    .then((res) => {
      // Manually include the email in the action payload
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: {
          message: res.data.message, // Assuming the API returns a message
          email: email, // Manually include the email
        },
      });
      return res.data;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: FORGOT_PASSWORD_FAIL,
      });
    });
};

// Add Forgot Password OTP to Redux
export const AddForgotPasswordToOTPAction = (otp) => (dispatch) => {
  dispatch({
    type: FORGOT_PASSWORD_OTP_ADDED_TO_REDUX_SUCCESS,
    payload: otp,
  });
};

//Comfirm Password
export const ConfirmPasswordAction =
  (email, otp, new_password) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email, otp, new_password });

    return axios
      .post(`/api/userauth/change-password/`, body, config)
      .then((res) => {
        dispatch({
          type: PASSWORD_CHANGED_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
          type: PASSWORD_CHANGED_FAIL,
        });
      });
  };

// Token Config
export const tokenConfig = (getState) => {
  const token = localStorage.getItem("access");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

// REFRESH TOKEN
export const refreshToken = () => async (dispatch) => {
  const refresh = localStorage.getItem("refresh");

  if (!refresh) {
    console.error("No refresh token available.");
    dispatch({ type: TOKEN_REFRESH_FAILED });
    return Promise.reject("No refresh token available");
  }

  try {
    const res = await axios.post("/api/auth/user/token/refresh/", { refresh });
    const newAccessToken = res.data.access;
    const newRefreshToken = res.data.refresh;
    console.log("Tokens refreshed:", newAccessToken, newRefreshToken);

    // Store the new access token
    localStorage.setItem("access", newAccessToken);
    localStorage.setItem("refresh", newRefreshToken);

    // Dispatch a success action
    dispatch({
      type: TOKEN_REFRESH_SUCCESS,
      payload: res.data,
    });

    return { newAccessToken, newRefreshToken }; // Return the new access and refresh token
  } catch (err) {
    console.error(
      "Token refresh failed:",
      err.response ? err.response.data : err.message
    );

    // Handle specific error cases
    if (err.response && err.response.status === 401) {
      console.log("Refresh token is invalid or expired. Logging out.");
      dispatch(LogoutAction()); // Trigger logout if refresh fails
    }

    // Dispatch a failure action
    dispatch({ type: TOKEN_REFRESH_FAILED });
    return Promise.reject(err);
  }
};

// LOGOUT USER
export const LogoutAction = () => (dispatch) => {
  const refresh = localStorage.getItem("refresh");

  if (!refresh) {
    dispatch({ type: FLUSH_ALL_AUTHENTICATION_REDUX_STATE });
    return;
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refresh}`,
    },
  };

  axios
    .post("/api/userauth/logout/", refresh, config) // No need for body unless your API requires it
    .then((response) => {
      dispatch({ type: LOGOUT_SUCCESS, payload: response.data });
      console.log("Logout successful:", response.data);
    })
    .catch((err) => {
      console.error(
        "Logout failed:",
        err.response ? err.response.data : err.message
      );
      if (err.response && err.response.status === 401) {
        console.log("Token was invalid or expired. Proceeding with cleanup.");
      }
    })
    .finally(() => {
      // Always clear tokens and state after logout
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      dispatch({ type: FLUSH_ALL_AUTHENTICATION_REDUX_STATE });
      console.log(
        "Logout process completed. Tokens removed and state cleared."
      );
    });
};

// Flush all Authentication data (this can be used elsewhere if needed)
export const FlushAuthenticationAction = () => (dispatch) => {
  dispatch({ type: FLUSH_ALL_AUTHENTICATION_REDUX_STATE });
};

export const GetUserAction = () => (dispatch, getState) => {
  axios
    .get(`/api/userauth/get-user/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FETCH_USER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: FETCH_USER_FAIL,
      });
    });
};
