import React, { Component } from "react";
import WhatsappChatList from "./WhatsappChatList";
import WhatsappChatStarted from "./WhatsappChatStarted";
class Whatsapp extends Component {
  render() {
    return (
      <div className="w-full h-screen bg-[#F9F9F9] p-[1rem]">
        <div className="flex bg-white w-full h-full gap-[1rem]">
          <WhatsappChatList />
          <WhatsappChatStarted />
        </div>
      </div>
    );
  }
}

export default Whatsapp;
