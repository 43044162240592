import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import DashboardSidebar from "./DashboardSidebar";
import DashboardHome from "../Dashboard/AnalysisAndExtract/DashboardHome";
import Void from "./Voids/Void";
import Void2 from "./Voids/Void2";
import Void3 from "./Voids/Void3";
import DataAnalysisSummary from "../Dashboard/AnalysisAndExtract//DataAnalysisSummary";
import AIChatBotSideBar from "./AIChatBot/AIChatBotSideBar";
import AIChatBot from "./AIChatBot/AIChatBot";
import AIModelsChatBotSideBar from "./AIModules/AIChatBot/AIChatBotSideBar";
import AIModelsChatBot from "./AIModules/AIChatBot/AIChatBot";
import PDFExtractorComparator from "../Dashboard/AnalysisAndExtract//PDFExtractorComparator";
import CollapseWindowBottonSVG from "../../Assets/svg/CollapseWindowBottonSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../withRouters/withRoutersHook";
import { CollapseCX2SidebarActiveAction } from "../../Actions/Interactions";
import FileProcessedSuccess from "../Dashboard/AnalysisAndExtract/FileProcessedSuccess";
import { ResetAnalysisReduxStateAction } from "../../Actions/Analysis";
import Messaging from "./Messaging/Messaging";
import MessagingSideBar from "./Messaging/MessagingSideBar";
import Whatsapp from "./Messaging/Whatsapp";
import Email from "./Messaging/Email";
import Smartflow from "../Dashboard/SmartFlow/SmartFlowHome";
import AIModuleHome from "./AIModules/AIModuleHome";
import SmartflowPreview from "./SmartFlow/SmartflowPreview";

class DashboardRouter extends Component {
  static propTypes = {
    isCollapsed: PropTypes.bool,
    CollapseCX2SidebarActiveAction: PropTypes.func.isRequired,
    CollapseCX2SidebarInactiveAction: PropTypes.func.isRequired,
    pdfAnalysisSuccess: PropTypes.bool,
    ResetAnalysisReduxStateAction: PropTypes.func.isRequired,
    isSentToMail: PropTypes.bool,
    csvAnalysisSuccess: PropTypes.bool,
  };

  state = {
    showSuccessPopup: false,
  };

  popupTimeout = null;

  handleCollapseEnable = () => {
    this.props.CollapseCX2SidebarActiveAction();
  };

  componentDidUpdate(prevProps) {
    if (
      (this.props.pdfAnalysisSuccess &&
        this.props.pdfAnalysisSuccess !== prevProps.pdfAnalysisSuccess &&
        !this.props.isSentToMail) ||
      (this.props.csvAnalysisSuccess &&
        this.props.csvAnalysisSuccess !== prevProps.csvAnalysisSuccess &&
        !this.props.isSentToMail)
    ) {
      console.log("this got triggered");
      this.setState({ showSuccessPopup: true });

      // Clear any previous timeout before setting a new one
      clearTimeout(this.popupTimeout);
      this.popupTimeout = setTimeout(() => {
        this.setState({ showSuccessPopup: false });
        if (
          this.props.location.pathname.includes("/dashboard/result-summary/")
        ) {
          return;
        } else {
          this.props.ResetAnalysisReduxStateAction();
        }
      }, 8000);
    }
  }

  componentWillUnmount() {
    // Clear timeout if component unmounts to prevent memory leaks
    clearTimeout(this.popupTimeout);
  }

  render() {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <div className="flex w-screen">
              <DashboardSidebar />
              <div className="bg-[#F9F9F9] w-[0.5rem] shrink-0"></div>
              <div className="flex w-full">
                <Void />
                <DashboardHome />
                {this.state.showSuccessPopup && <FileProcessedSuccess />}
              </div>
            </div>
          }
        />
        <Route
          path="/pdf-analyzer"
          element={
            <div className="flex w-screen">
              <DashboardSidebar />
              <div className="bg-[#F9F9F9] w-[0.5rem] shrink-0"></div>
              <div className="flex w-full">
                <Void />
                <DashboardHome />
                {this.state.showSuccessPopup && <FileProcessedSuccess />}
              </div>
            </div>
          }
        />
        <Route
          path="/result-summary/:id"
          element={
            <div className="flex w-screen">
              <DashboardSidebar />
              <div className="bg-[#F9F9F9] w-[0.5rem] shrink-0"></div>
              <div className="flex w-full">
                <Void />
                <DataAnalysisSummary />
              </div>
            </div>
          }
        />
        <Route
          path="/pdf-analyzer/result-summary/:id"
          element={
            <div className="flex w-screen">
              <DashboardSidebar />
              <div className="bg-[#F9F9F9] w-[0.5rem] shrink-0"></div>
              <div className="flex w-full">
                <Void />
                <DataAnalysisSummary />
              </div>
            </div>
          }
        />
        <Route
          path="/ai-chatbot"
          element={
            <div className="flex w-screen">
              {this.props.isCollapsed && <AIChatBotSideBar />}
              {!this.props.isCollapsed && (
                <button className="fixed z-50 top-[3rem] left-[2rem]">
                  <img
                    src={CollapseWindowBottonSVG}
                    onClick={this.handleCollapseEnable}
                    alt="Collapse"
                  />
                </button>
              )}
              <div className="bg-[#F9F9F9] w-[0.5rem] shrink-0"></div>
              <div className="flex w-full">
                {this.props.isCollapsed && <Void2 />}
                <AIChatBot />
                {this.state.showSuccessPopup && <FileProcessedSuccess />}
              </div>
            </div>
          }
        />
        <Route
          path="/ai-chatbot/:sessionId"
          element={
            <div className="flex w-screen">
              {this.props.isCollapsed && <AIChatBotSideBar />}
              {!this.props.isCollapsed && (
                <button className="fixed z-50 top-[3rem] left-[2rem]">
                  <img
                    src={CollapseWindowBottonSVG}
                    onClick={this.handleCollapseEnable}
                    alt="Collapse"
                  />
                </button>
              )}
              <div className="bg-[#F9F9F9] w-[0.5rem] shrink-0"></div>
              <div className="flex w-full">
                {this.props.isCollapsed && <Void2 />}
                <AIChatBot />
                {this.state.showSuccessPopup && <FileProcessedSuccess />}
              </div>
            </div>
          }
        />
        <Route
          path="/pdf-analyzer/result-summary/:id/compare"
          element={
            <div className="flex w-screen">
              <DashboardSidebar />
              <div className="bg-[#F9F9F9] w-[0.5rem] shrink-0"></div>
              <div className="flex w-full">
                <Void />
                <PDFExtractorComparator />
              </div>
            </div>
          }
        />
        <Route
          path="/message-overview"
          element={
            <div className="flex w-screen">
              <MessagingSideBar />
              <div className="flex w-full overflow-hidden">
                <Void3 />
                <Messaging />
              </div>
            </div>
          }
        />
        <Route
          path="/message-overview/whatsapp"
          element={
            <div className="flex w-screen">
              <MessagingSideBar />
              <div className="flex w-full overflow-hidden">
                <Void3 />
                <Whatsapp />
              </div>
            </div>
          }
        />
        <Route
          path="/message-overview/email"
          element={
            <div className="flex w-screen">
              <MessagingSideBar />
              <div className="flex w-full overflow-hidden">
                <Void3 />
                <Email />
              </div>
            </div>
          }
        />
        <Route
          path="/smartflow"
          element={
            <div className="flex w-screen">
              <div className="flex w-full overflow-hidden">
                <Smartflow />
              </div>
            </div>
          }
        />
        <Route
          path="/ai-models"
          element={
            <div className="flex w-screen">
              <div className="flex w-full overflow-hidden">
                <AIModuleHome />
              </div>
            </div>
          }
        />
        <Route
          path="/smartflow/preview/:id"
          element={
            <div className="flex w-screen">
              <div className="flex w-full overflow-hidden">
                <SmartflowPreview />
              </div>
            </div>
          }
        />
        <Route
          path="/ai-models/ai-chatbot"
          element={
            <div className="flex w-screen">
              {this.props.isCollapsed && <AIModelsChatBotSideBar />}
              {!this.props.isCollapsed && (
                <button className="fixed z-50 top-[3rem] left-[2rem]">
                  <img
                    src={CollapseWindowBottonSVG}
                    onClick={this.handleCollapseEnable}
                    alt="Collapse"
                  />
                </button>
              )}
              <div className="bg-[#F9F9F9] w-[0.5rem] shrink-0"></div>
              <div className="flex w-full">
                {this.props.isCollapsed && <Void2 />}
                <AIModelsChatBot />
                {this.state.showSuccessPopup && <FileProcessedSuccess />}
              </div>
            </div>
          }
        />
        <Route
          path="/ai-models/ai-chatbot/:sessionId"
          element={
            <div className="flex w-screen">
              {this.props.isCollapsed && <AIModelsChatBotSideBar />}
              {!this.props.isCollapsed && (
                <button className="fixed z-50 top-[3rem] left-[2rem]">
                  <img
                    src={CollapseWindowBottonSVG}
                    onClick={this.handleCollapseEnable}
                    alt="Collapse"
                  />
                </button>
              )}
              <div className="bg-[#F9F9F9] w-[0.5rem] shrink-0"></div>
              <div className="flex w-full">
                {this.props.isCollapsed && <Void2 />}
                <AIModelsChatBot />
                {this.state.showSuccessPopup && <FileProcessedSuccess />}
              </div>
            </div>
          }
        />
      </Routes>
    );
  }
}

const mapStateToProps = (state) => ({
  isCollapsed: state.interactions.isCollapsed,
  pdfAnalysisSuccess: state.analysis.pdfAnalysisSuccess,
  isSentToMail: state.analysis.isSentToMail,
  csvAnalysisSuccess: state.analysis.csvAnalysisSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    CollapseCX2SidebarActiveAction,
    ResetAnalysisReduxStateAction,
  })(DashboardRouter)
);
