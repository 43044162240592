import React, { Component } from "react";
import { ReactTyped as Typed } from "react-typed";
import MoreInformationIllustrationSVG from "../../../Assets/svg/MoreInformationIllustrationSVG.svg";

class SmartFlowMoreInformationDialogue extends Component {
  render() {
    return (
      <div className="w-[90%] h-[28.938rem] flex flex-col justify-center items-center bg-[#FFFFFF] rounded-[1rem] gap-[1rem]">
        <img
          className=""
          src={MoreInformationIllustrationSVG}
          alt="MoreInformationIllustrationSVG"
        />
        <div className="font-[500] font-inter text-[2rem] w-[65.125rem] h-[8.875rem] text-center">
          <Typed
            strings={[
              "Please read the instructions carefully before entering a response. You will be asked a series of questions and your answers to the questions will be used to help you fill out the form",
            ]}
            typeSpeed={15}
          />
          <div className="font-inter font-[500] text-[1.75rem] text-[#3366CC]">
            Click anywhere to Skip
          </div>
        </div>
      </div>
    );
  }
}

export default SmartFlowMoreInformationDialogue;
