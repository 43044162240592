import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// PrivateRoute Component
const PrivateRoute = ({ authentication, children }) => {
  const location = useLocation();

  if (!authentication.isAuthenticated) {
    const redirectTo = location.pathname.startsWith("/admin")
      ? "/admin-login"
      : "/login";

    return <Navigate to={redirectTo} replace state={{ from: location }} />;
  }

  return children;
};

PrivateRoute.propTypes = {
  authentication: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
});

// Connected PrivateRoute Component
const ConnectedPrivateRoute = connect(mapStateToProps)(PrivateRoute);

// HandleBack Component
const HandleBack = () => {
  const location = useLocation(); // Using React Router's useLocation hook to access the current path

  if (location.pathname === "/dashboard/result-summary") {
    return <Navigate to="/dashboard" replace />;
  }
  if (location.pathname === "/dashboard/pdf-analyzer/result-summary") {
    return <Navigate to="/dashboard/pdf-analyzer" />;
  }

  return null; // Return null if no navigation is required
};

// Export each component separately
export { ConnectedPrivateRoute as PrivateRoute, HandleBack };
