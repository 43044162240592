import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RedArrowSVG from "../../../Assets/svg/RedArrowDownSVG.svg";
import { FetchCSVAction, DeleteCSVAction } from "../../../Actions/Analysis";
import { withRouterHooks } from "../../../withRouters/withRoutersHook";
import TrashSVG from "../../../Assets/svg/TrashSVG.svg";
import {
  FetchIndividualFileAction,
  AddAnalysisFileIDToRedux,
} from "../../../Actions/Analysis";

class UploadFileStatus extends Component {
  static propTypes = {
    FetchCSVAction: PropTypes.func.isRequired,
    DeleteCSVAction: PropTypes.func.isRequired,
    csvData: PropTypes.array,
    location: PropTypes.object.isRequired,
    FetchIndividualFileAction: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    csvAnalysisSuccessReport: PropTypes.string,
    csvAnalysisSuccess: PropTypes.bool,
    // AddAnalysisFileIDToReduxAction: PropTypes.func.isRequired,
    pdfAnalysisSuccess: PropTypes.bool,
  };

  componentDidMount() {
    // Fetch all files on component mount
    this.props.FetchCSVAction();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.pdfAnalysisSuccess &&
        this.props.pdfAnalysisSuccess != prevProps.pdfAnalysisSuccess) ||
      (this.props.csvAnalysisSuccess &&
        this.props.csvAnalysisSuccess != prevProps.csvAnalysisSuccess)
    ) {
      this.props.FetchCSVAction();
    }
  }

  // Your need to look into this bug and fix it, it is connected with
  // the DataAnalysisSummary.js so for some reason it renders view csv
  // even without data being available although it displays "Data not avaliabe"
  // but doesnt render view pdf even when there is no data to render. I believe
  // showing "Data not available" should be the same for both pdf and csv
  // Its is also connected to the FetchIndividualFileAction action and the
  // FETCH_INDIVIDUAL_FILE_SUCCESS redux
  handleViewFile = (id) => {
    this.props.FetchIndividualFileAction(id).then((res) => {
      if (res?.file_type === "CSV") {
        this.props.navigate(`result-summary/${id}`);
      }
      if (res?.file_type === "PDF") {
        this.props.navigate(`/dashboard/pdf-analyzer/result-summary/${id}`);
      }
    });
  };

  handleDelete = (id) => {
    // Delete a file
    this.props.DeleteCSVAction(id);
    setTimeout(() => {
      this.props.FetchCSVAction();
    }, 1000);
  };

  handleFileFilter = () => {
    const { csvData, location } = this.props;

    if (!csvData || !Array.isArray(csvData)) {
      return [];
    }

    return csvData.filter((file) => {
      if (location.pathname === "/dashboard" && file?.file_type === "CSV") {
        return true;
      } else if (
        location.pathname === "/dashboard/pdf-analyzer" &&
        file?.file_type === "PDF"
      ) {
        return true;
      }
      return false;
    });
  };

  render() {
    const filteredData = this.handleFileFilter();

    return (
      <div className="flex flex-col w-full h-fit gap-[1.75rem]">
        <div className="flex justify-between font-SFPro items-center">
          <div className="text-[2rem] font-[700] ">File Upload Status</div>
          <div className="font-[400] text-[1.5rem] leading-[1.79rem] text-[#8E3B8D] underline cursor-pointer">
            View all
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex w-full h-[4.063rem] font-inter font-[500] text-[1.25rem] leading-[1.513rem] bg-[#F2F2F3] items-center">
            <div className="grid grid-cols-5 pl-[1rem] w-full text-left">
              <div>File Name</div>
              <div className="relative left-[2.5rem]">Date Processed</div>
              <div className="relative left-[5rem]">File Size</div>
              <div className="relative left-[2.5rem]">Status</div>
              <div>Actions</div>
            </div>
          </div>
          <div className="flex w-full flex-col ">
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((file) => (
                <div
                  key={file.id}
                  className="grid grid-cols-5 w-full h-[4.5rem] items-center border-[1px] border-[#F1F2F3] pl-[1rem] font-inter font-[400] text-[1.125rem] leading-[1.361rem]"
                >
                  <div>{file.file_name || "No file name"}</div>
                  <div className="relative w-fit left-[2.5rem]">
                    {file.date_processed || "In Progress"}
                  </div>
                  <div className="relative w-fit left-[5rem]">
                    {file.file_size || "Unknown size"}
                  </div>
                  <div
                    className={`${
                      file.processing_status === "Not Processed"
                        ? "text-[#EC474A]"
                        : file.processing_status === "Pending"
                        ? "text-[#FFD700]"
                        : "text-[#55BC0A]"
                    } relative left-[2.5rem] w-fit`}
                  >
                    {file.processing_status}
                  </div>
                  <div className="flex gap-[2rem] w-fit">
                    <button className="flex gap-[1rem] h-[3rem] items-center">
                      {file.processing_status === "Not Processed" ? (
                        <>
                          <div>Retry</div>
                          <img src={RedArrowSVG} alt="Red Arrow" />
                        </>
                      ) : (
                        <div
                          onClick={() => this.handleViewFile(file.id)}
                          className="text-[#3366CC]"
                        >
                          View File
                        </div>
                      )}
                    </button>
                    <button onClick={() => this.handleDelete(file.id)}>
                      <img src={TrashSVG} alt="Trash" />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-4">No files available</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  csvData: state.analysis.csvData, // Files from the Redux store
  pdfAnalysisSuccess: state.analysis.pdfAnalysisSuccess,
  csvAnalysisSuccess: state.analysis.csvAnalysisSuccess,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    FetchCSVAction,
    DeleteCSVAction,
    FetchIndividualFileAction,
    AddAnalysisFileIDToRedux,
  })(UploadFileStatus)
);
