import React, { Component } from "react";
import EmailChatList from "./EmailChatList";
import EmailChatStarted from "./EmailChatStarted";

class Email extends Component {
  render() {
    return (
      <div className="w-full h-screen bg-[#F9F9F9] p-[1rem]">
        <div className="flex bg-white w-full h-full gap-[1rem]">
          <EmailChatList />
          <EmailChatStarted />
        </div>
      </div>
    );
  }
}

export default Email;
