import React, { Component } from "react";
import CESAIICONSVG from "../../../../Assets/svg/CESAIICONSVG.svg";
import ShareIconSVG from "../../../../Assets/svg/ShareIconSVG.svg";
import SpeakerIconSVG from "../../../../Assets/svg/SpeakerIconSVG.svg";
import CopyIconSVG from "../../../../Assets/svg/CopyIconSVG.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";
import Ripple from "../../../Animations/Ripple/Ripple";
import {
  FetchIndividualChatSessionAction,
  ResetIsNewChatSession,
  ClearIndividualChatSession,
} from "../../../../Actions/AI";

class ChatStarted extends Component {
  static propTypes = {
    individualChatSession: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        role: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        timestamp: PropTypes.string.isRequired,
      })
    ),
    isIndividualChatSessionFetchSuccess: PropTypes.bool,
    FetchIndividualChatSessionAction: PropTypes.func.isRequired,
    continueChatSessionId: PropTypes.number,
    ResetIsNewChatSession: PropTypes.func.isRequired,
    ClearIndividualChatSession: PropTypes.func.isRequired,
    individualChatSessionFetchSuccess: PropTypes.bool,
    individualChatSessionFailMessage: PropTypes.string,
    navigate: PropTypes.func.isRequired,
  };

  state = {
    speakingMessageId: null, // Tracks the ID of the message currently being spoken
  };

  chatEndRef = React.createRef();

  componentDidMount() {
    this.props.ClearIndividualChatSession();
    const {
      FetchIndividualChatSessionAction,
      continueChatSessionId,
      location,
    } = this.props;
    const id = this.props.location.pathname.split("/").pop();
    if (
      location.pathname.includes("/dashboard/ai-chatbot/") &&
      continueChatSessionId
    ) {
      FetchIndividualChatSessionAction(id);
    }
    this.props.ResetIsNewChatSession();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.individualChatSession !== this.props.individualChatSession) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    if (this.chatEndRef.current) {
      this.chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleTextToSpeech = (messageId, text) => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      this.setState({ speakingMessageId: null });
    } else {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.onend = () => {
        this.setState({ speakingMessageId: null }); // Reset speakingMessageId after speech ends
      };
      speechSynthesis.speak(utterance);
      this.setState({ speakingMessageId: messageId }); // Set the current speaking message
    }
  };

  renderMessages() {
    const { individualChatSession } = this.props;

    if (!individualChatSession || !Array.isArray(individualChatSession)) {
      return <div>No chat sessions available.</div>;
    }

    return individualChatSession.map((message) => (
      <div key={message.id} className="flex flex-col gap-[1rem] w-full h-fit">
        {message.role === "user" ? (
          <div className="flex w-full h-fit pr-[3rem] pl-[2rem] justify-end">
            <div className="w-fit max-w-[41.875rem] h-fit px-[1.75rem] py-[1rem] rounded-[7.75rem] bg-[#EEEDED]">
              {message.content}
            </div>
          </div>
        ) : (
          <div className="flex gap-[2rem] w-full h-fit justify-start">
            <img
              src={CESAIICONSVG}
              alt="CES AI ICON"
              className="w-[3.5rem] h-[3.5rem] ml-[2rem]"
            />
            <div
              className="w-fit max-w-[60rem] h-fit text-gray-700 bot-message-content"
              dangerouslySetInnerHTML={{ __html: message.content }}
            />
          </div>
        )}

        {message.role === "bot" && (
          <div className="flex w-[7rem] gap-[0.5rem] relative left-[7.5rem] items-center">
            <button
              onClick={() =>
                this.handleTextToSpeech(message.id, message.content)
              }
              className="flex justify-center items-center"
            >
              {this.state.speakingMessageId === message.id ? (
                <Ripple /> // Render ripple effect for the speaking message
              ) : (
                <img src={SpeakerIconSVG} alt="speaker" /> // Render speaker icon for other messages
              )}
            </button>
            <button
              onClick={() => navigator.clipboard.writeText(message.content)}
            >
              <img src={CopyIconSVG} alt="copy" />
            </button>
            <button>
              <img src={ShareIconSVG} alt="share" />
            </button>
          </div>
        )}
      </div>
    ));
  }

  render() {
    return (
      <div className="flex w-full h-full bg-white justify-center max-w-[100rem] relative">
        <div className="rounded-lg flex flex-col gap-[2rem] h-full overflow-y-auto pb-[10rem] px-[2rem]">
          {this.renderMessages()}
          <div ref={this.chatEndRef} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  individualChatSession: state.ai.individualChatSession,
  isIndividualChatSessionFetchSuccess:
    state.ai.isIndividualChatSessionFetchSuccess,
  continueChatSessionId: state.ai.continueChatSessionId,
  individualChatSessionFetchSuccess: state.ai.individualChatSessionFetchSuccess,
  individualChatSessionFailMessage: state.ai.individualChatSessionFailMessage,
});

const mapDispatchToProps = {
  FetchIndividualChatSessionAction,
  ResetIsNewChatSession,
  ClearIndividualChatSession,
};

export default withRouterHooks(
  connect(mapStateToProps, mapDispatchToProps)(ChatStarted)
);
