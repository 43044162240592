// AIInput.js

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SendIconSVG from "../../../../Assets/svg/SendIconSVG.svg";
import StopCircleSVG from "../../../../Assets/svg/StopCircleSVG.svg";
import {
  chatInputAction,
  FetchIndividualChatSessionAction,
  resetHasResponded,
  StartNewChatSessionAction,
  ContinueChatSessionAddSessionIDToReduxAction,
  FetchChatSessionsAction,
  hasClicked,
  hasNotClicked,
  AddAIModelToRedux,
} from "../../../../Actions/AI";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouterHooks } from "../../../../withRouters/withRoutersHook";

class AIInput extends Component {
  state = {
    message: "",
  };

  static propTypes = {
    FetchIndividualChatSessionAction: PropTypes.func.isRequired,
    continueChatSessionId: PropTypes.number,
    chatInputAction: PropTypes.func.isRequired,
    hasResponded: PropTypes.bool,
    resetHasResponded: PropTypes.func.isRequired,
    isNewChatSession: PropTypes.bool,
    isContinueChatSessionIdAddedToRedux: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
    FetchChatSessionsAction: PropTypes.func.isRequired,
    StartNewChatSessionAction: PropTypes.func.isRequired,
    ContinueChatSessionAddSessionIDToReduxAction: PropTypes.func.isRequired,
    isClicked: PropTypes.bool,
    AddAIModelToRedux: PropTypes.func.isRequired,
    model: PropTypes.string,
    modelName: PropTypes.string,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.hasResponded && this.props.hasResponded) {
      const id = this.props.location.pathname.split("/").pop();
      this.props.FetchIndividualChatSessionAction(id);

      // this.setState({ ishasResponded: true, isClicked: false });

      setTimeout(() => {
        this.props.resetHasResponded();
        // this.setState({ ishasResponded: false });
        this.props.hasNotClicked();
      }, 1000);
    }
  }

  handleInputChange = (event) => {
    this.setState({ message: event.target.value });
  };

  handleSendClick = () => {
    const { message } = this.state;
    const {
      continueChatSessionId,
      chatInputAction,
      isNewChatSession,
      StartNewChatSessionAction,
      ContinueChatSessionAddSessionIDToReduxAction,
      navigate,
      FetchChatSessionsAction,
      location,
    } = this.props;

    if (message.trim()) {
      if (isNewChatSession && !continueChatSessionId) {
        StartNewChatSessionAction().then((data) => {
          if (data && data.id) {
            // Update Redux with the new session ID
            ContinueChatSessionAddSessionIDToReduxAction(data.id);
            // Navigate to the new chat session
            navigate(`/dashboard/ai-models/ai-chatbot/${data.id}`);
            // Send the message with the new session ID
            chatInputAction(message, data.id, this.props?.model || "genii-d");
            // Fetch updated chat sessions
            FetchChatSessionsAction();
          }
        });
      } else if (continueChatSessionId && !isNewChatSession) {
        // Extract session ID from the current path
        const path = location?.pathname?.split("/");
        const id = path[path.length - 1];
        if (id) {
          // Send the message with the extracted session ID,
          chatInputAction(message, id, this.props.model || "genii-d");
        }
      }
      // Reset the message input and update state
      this.setState({ message: "" });

      this.props.hasClicked();
    }
  };

  render() {
    return (
      <div className="w-full flex flex-col gap-[0.5rem]">
        <div className="relative w-full">
          <input
            type="text"
            value={this.state.message}
            onChange={this.handleInputChange}
            className="w-full h-[4.688rem] placeholder:text-[1.25rem] text-[1.25rem] bg-[#F7F8FA] rounded-[2.063rem] border-[1px] border-[#D5D7DA] focus:border-transparent focus:outline-none px-[2rem]"
            placeholder="Chat with CESGPT"
          />
          <button
            onClick={this.handleSendClick}
            className="absolute bottom-[0.75rem] right-[1rem] rounded-[6.313rem] flex w-[8.5rem] h-[3.188rem] bg-[#A12F9F] justify-center items-center"
          >
            {this.props.isClicked && !this.props.ishasResponded ? (
              <div className="pulsing-icon">
                <img src={StopCircleSVG} alt="Stop Circle" />
              </div>
            ) : (
              <div className="flex gap-[0.625rem]">
                <div className="text-[#FFFFFF] font-[600] font-inter text-[1.5rem]">
                  Send
                </div>
                <img src={SendIconSVG} alt="Send Icon" />
              </div>
            )}
          </button>
        </div>
        <div className="font-[500] font-SFPro text-[1.25rem] flex items-center justify-center">
          All data exchange is safe with us
          <Link to="/learn-more" className="text-[#3366CC] ml-1 font-[400]">
            Learn more
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  continueChatSessionId: state.ai.continueChatSessionId,
  hasResponded: state.ai.hasResponded,
  isNewChatSession: state.ai.isNewChatSession,
  isContinueChatSessionIdAddedToRedux:
    state.ai.isContinueChatSessionIdAddedToRedux,
  isClicked: state.ai.isClicked,
  model: state.ai.model,
  modelName: state.ai.modelName,
});

export default withRouterHooks(
  connect(mapStateToProps, {
    chatInputAction,
    FetchIndividualChatSessionAction,
    resetHasResponded,
    StartNewChatSessionAction,
    ContinueChatSessionAddSessionIDToReduxAction,
    FetchChatSessionsAction,
    hasClicked,
    hasNotClicked,
    AddAIModelToRedux,
  })(AIInput)
);
